<script>
  // sc-common と sinput_sc_name のmixin用のコンポーネント。
  import scCommon from './sc_common.vue';
  import sinputScName from './sinput_sc_name.vue';
  export default {
    mixins: [scCommon],
    props: {
      largep: {
        default: true
      },
      placeholder: {default: '「めまい」など症状を追加する'},
    },
    data () {
      return {
        x_style: {},
      };
    },
    created () {
      var vm = this;
      if (vm.largep) {
        vm.x_style = {height: '3.5em'};
      }
    },
    components: {
      sinputScName,
    },
  };
</script>
<template>
  <div>
    <sinput-sc-name
        :p_style="x_style"
        :except="{}"
        @select="add_symptom"
        :placeholder="placeholder"
    />
  </div>
</template>
