<script>
  import lib from './lib.vue';
  export default {
    mixins: [lib],
    props: {
      'p_search': {},
    },
    computed: {
      qs: {
        set (x) {
          var vm = this;
          vm.$set(vm.$store.state, 'qs', x);
        },
        get () {
          var vm = this;
          return vm.$store.state.qs;
        },
      },
    },
    created () {
      var vm = this;
      window.dsbdd = vm;

      // 親に任せる。
      // vm.$set(vm.$store.state, 'qs', {});

      vm.qform_opts = [
        {value: '', name: 'すべて'},
      ];
      [
        '-', // seplattion
        '錠剤',
        'カプセル剤',
        '散剤',
        '液剤',
        '内用薬その他',
        '-',
        '注射剤',
        '貼付剤',
        '挿入剤',
        '皮膚塗布剤',
        '目・耳鼻用剤',
        '外用薬その他',
        '-',
        '歯科用剤',
      ].forEach(x=>{
        vm.qform_opts.push({value: x, name: x});
      });

      vm.qinfo_list = [
        {id: 'q', name: 'キーワード'},
        {id: 'qcompo', name: '成分'},
        {id: 'qmanu', name: '製薬会社'},
        {id: 'qdisease', name: '病気'},
        {id: 'qcode', name: 'コード'},
        {id: 'qgenericp', name: '先発・後発'},
        {id: 'qform', name: '剤形'},

        {id: 'qcategory0', name: 'カテゴリ0'},
        {id: 'qcategory1', name: 'カテゴリ1'},
        {id: 'qcategory2', name: 'カテゴリ2'},
        {id: 'qcategory3', name: 'カテゴリ3'},
      ];

      vm.qinfo = {};
      vm.qinfo_list.forEach(x=>{
        vm.qinfo[x['id']] = x;
      });

      vm.logd("mode_from_uri:", vm.mode_from_uri());
      if (vm.mode_from_uri() === 21) {
        var qh = vm.$qs.parse(location.search);
        Object.keys(vm.qinfo).forEach(x=>{
          vm.$set(vm.qs, x, qh[x] || '');
        });
      }
    },
    methods: {
      category_changed (i) {
        var vm = this;
        if (i === 0) {
          vm.$store.state.qs.qcategory1 = '';
          vm.$store.state.qs.qcategory2 = '';
          vm.$store.state.qs.qcategory3 = '';
        }
        if (i === 1) {
          vm.$store.state.qs.qcategory2 = '';
          vm.$store.state.qs.qcategory3 = '';
        }
        if (i === 2) {
          vm.$store.state.qs.qcategory3 = '';
        }
      },
      inputed (place) {
      },
      search () {
        // nop.
        // 条件換えるたびに検索するのは鬱陶しいので、一旦止める。
      },
      clear_qs () {
        var vm = this;
        // TODO: rethink.
        Object.keys(vm.qinfo).forEach(x=>{
          vm.$set(vm.qs, x, '');
        });
      },
    },
  };
</script>

<template>
  <div class='bulma' style='margin: 0; padding: 0;'>
    <div
        ref='detail'
        style='overflow: hidden; margin: 16px 0 0; padding: 0;'
    >
      <!-- ------------------------------ -->
      <p v-text='"先発・後発:"' />
      <div class='field'>
        <div class="control">
          <div class="select is-fullwidth">
            <select v-model='qs.qgenericp' @change='search()'>
              <option value='' >すべて</option>
              <option value='1'>{{qgenericp_to_name(1)}}</option>
              <option value='2'>{{qgenericp_to_name(2)}}</option>
            </select>
          </div>
        </div>
      </div>

      <p v-text='qinfo["qdisease"]["name"] + ""' />
      <div class='field has-addons'>
        <div class='control is-expanded'>
          <input class='input' v-model='qs.qdisease'
                 @keypress.enter='p_search()' />
        </div>
        <div class='control'>
          <div class='button' v-text='"クリア"' @click='qs.qdisease = ""; search();' />
        </div>
      </div>

      <p v-text='qinfo["qcompo"]["name"] + ""' />
      <div class='field has-addons'>
        <div class='control is-expanded'>
          <input class='input'
                 v-model='qs.qcompo'
                 @keypress.enter='p_search(); inputed("qcompo")'
                 @keyup.enter='inputed("qcompo")'
          />
        </div>
        <div class='control'>
          <div class='button' v-text='"クリア"' @click='qs.qcompo = ""; search();' />
        </div>
      </div>

      <p v-text='qinfo["qmanu"]["name"] + ""' />
      <div class='field has-addons'>
        <div class='control is-expanded'>
          <input class='input'
                 v-model='qs.qmanu'
                 @keypress.enter='p_search' />
        </div>
        <div class='control'>
          <div class='button' v-text='"クリア"' @click='qs.qmanu = ""; search();' />
        </div>
      </div>

      <p v-text='qinfo["qform"]["name"] + ""' />
      <div class='field'>
        <div class="control">
          <div class="select is-fullwidth">
            <select v-model='qs.qform' @change='search()'>
              <template v-for='(opt, i) in qform_opts'>
                <option v-if='opt.name == "-"' v-text='"------"' disabled :key='i' />
                <option v-else                 :value='opt.value' v-text='opt.name' :key='i' />
              </template>
            </select>
          </div>
        </div>
      </div>


      <template v-if='presentp($store.state.search_condition)'>
        <p v-text='"カテゴリ"' />
        <div class='field'>
          <div class='control is-expanded'>
            <div class="select is-fullwidth">
              <select v-model='$store.state.qs.qcategory0' @change='category_changed(0)'>
                <option value=''>--</option>
                <option v-for='x in $store.state.search_condition.prescription_category.root' :value='x.id' :key='x.id'>
                  {{x.name}}
                </option>
              </select>
            </div>
          </div>
        </div>
        <template v-if='presentp($store.state.qs.qcategory0) && $store.state.qs.qcategory0 != ""'>
          <div class='field'>
            <div class='control is-expanded'>
              <div class="select is-fullwidth">
                <select v-model='$store.state.qs.qcategory1' @change='category_changed(1)'>
                  <option value=''>--</option>
                  <option v-for='x in $store.state.search_condition.prescription_category[$store.state.qs.qcategory0]' :value='x.id' :key='x.id'>
                    {{x.name}}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </template>

        <template v-if='presentp($store.state.qs.qcategory1) && $store.state.qs.qcategory1 != ""'>
          <div class='field'>
            <div class='control is-expanded'>
              <div class="select is-fullwidth">
                <select v-model='$store.state.qs.qcategory2'>
                  <option value=''>--</option>
                  <option v-for='x in $store.state.search_condition.prescription_category[$store.state.qs.qcategory1]' :value='x.id' :key='x.id'>
                    {{x.name}}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </template>


        <template v-if='presentp($store.state.qs.qcategory2) && $store.state.qs.qcategory2 != ""'>
          <div class='field'>
            <div class='control is-expanded'>
              <div class="select is-fullwidth">
                <select v-model='$store.state.qs.qcategory3'>
                  <option value=''>--</option>
                  <option v-for='x in $store.state.search_condition.prescription_category[$store.state.qs.qcategory2]' :value='x.id' :key='x.id'>
                    {{x.name}}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </template>

      </template>

      <div style='height: 8px;' />

      <div class='field'>
        <div class="control has-text-centered">

          <button class='button is-expanded xis-large' @click='clear_qs'>
            全てクリア
          </button>
          &nbsp;&nbsp;
          <button
              class='button is-expanded xis-large is-primary'
              @click='p_search({enforcep: true})'>
            <i class='fa fa-search'></i>
            &nbsp;
            検索
          </button>
        </div>
      </div>
      <div style='hight: 8px;' />
    </div>
  </div>
</template>

<style scoped>
  p {
    font-weight: bold;
    margin: 0;
  }
  .card {
    display: block;
  }
  .card.closedp {
    display: none;
  }
  .card-content {
    padding: 12px;
  }
</style>
