<script>
  //var agov = {
  //  template: "<a href='https://www.mhlw.go.jp/stf/seisakunitsuite/bunya/kenkou_iryou/iryou/teikyouseido/index.html' target='_blank'>医療情報ネット</a>",
  //  methods: {
  //    render (arg) {
  //      var vm = this;
  //      return arg;
  //      return vm.$createElement('div', {}, ["aaaa"]);
  //    }
  //  }
  //};

  // use zen desk api.
  import lib from './lib.vue';
  export default {
    mixins: [lib],
    props: {
      ar_type_text: null,
      subject: { default: '' },

      p_uri: null,

      categ: { default: '' },
    },
    data () {
      return {
        uri: null,

        type_text: '',

        name: '',
        org: '',
        email: '',
        email_to_check: '',
        phone: '',
        body: '',

        // uri: '',
      };
    },
    created () {
      var vm = this;
      if (vm.ar_type_text) {
        vm.type_text = vm.ar_type_text[0];
      }
      vm.uri = vm.p_uri || window.location.toString();
    },
    computed: {
      institution_p () {
        var vm = this;
        if (vm.uri && (vm.uri.match(/institution/))) {
          return true;
        }
        if (vm.type_text.match(/病院/)) {
          return true;
        }
        return null;
      },
      invalidp () {
        var vm = this;
        return vm.invalidp_name ||
               // vm.invalidp_org ||
               vm.invalidp_email !== 0 ||
               vm.invalidp_body;
      },
      invalidp_name () {
        var vm = this;
        return !vm.presentp(vm.name);
      },
      // invalidp_org () {
      //   var vm = this;
      //   if (!vm.presentp(vm.org)) { return 1; }
      // },
      invalidp_email () {
        var vm = this;
        if (! vm.presentp(vm.email)) {
          return 1;
        }
        var re = /^[^@]+@.+\..+$/;
        if (! re.test(vm.email)) {
          return 2;
        }
        if (vm.email !== vm.email_to_check) {
          return 3;
        }
        return 0;
      },
      invalidp_body () {
        var vm = this;
        return !vm.presentp(vm.body);
      },
    },
    methods: {
      cont () {
        var vm = this;
        var cont = {};
        ['name', 'org', 'email', 'phone', 'body', 'type_text'].forEach(x=>{
          cont[x] = vm[x];
        });
        cont.subject = vm.subject;

        cont.uri = vm.uri || window.location.toString();
        cont.categ = vm.categ || 'categ:uncateg';

        return cont;
      },
      send () {
        var vm = this;

        var cont = vm.cont();

        vm.mhttp(
          'post',
          '/api/u/contact',
          cont,
          res=>{
            vm.logd('res:', res);
            if (res.error) {
              vm.logd('error');
            }

            // vm.toast('お問い合わせを受けつけました');
            var opts = {};
            opts.type = 'dialog';
            opts.title = 'お問い合わせを受け付けました';
            opts.body = '<div class="has-text-centered">お問い合わせありがとうございます。<br/>確認の上回答させていただきます。</div>';
            vm.modal(opts);

            vm.name = '';
            vm.org = '';
            vm.email = '';
            vm.email_to_check = '';
            vm.phone = '';
            vm.body = '';
          },
          err=>{
            vm.toast('通信エラーが発生しました');
          });
      },
    },
  };
</script>

<template>
  <div>

    <div class='field'>
      <div class='control is-expanded'>
        <label class="label">
          ご氏名
          <span
            class="tag is-primary"
            v-text='"必須"'
            v-if='invalidp_name'
          />
        </label>
        <input type="text" class="input" v-model='name' />
      </div>
    </div>

    <div class='field'>
      <div class='control is-expanded'>
        <label class="label">
          法人名・団体名
        </label>
        <input type="text" class="input" v-model='org' />
      </div>
    </div>


    <div class='field'>
      <div class='control is-expanded'>
        <label class="label">
          メールアドレス
          <span class="tag is-primary"
                v-if='invalidp_email == 1'
                v-text='"必須"'
          />
        </label>
        <input type="text"
               class="input"
               :class='{"is-danger": invalidp_email == 2}'
               v-model='email' />
        <p class="help is-danger" v-if='invalidp_email == 2'>
          正しい形式でメールアドレスを入力してください。
        </p>
      </div>
    </div>

    <div class='field'>
      <div class='control is-expanded'>
        <label class="label">
          メールアドレス(確認)
        </label>
        <input type="text"
               class="input"
               :class='{"is-danger": invalidp_email == 3}'
               v-model='email_to_check' />
        <p class="help is-danger" v-if='invalidp_email == 3'>
          確認のため同一のメールアドレスを入力してください。
        </p>
      </div>
    </div>



    <div class='field'>
      <div class='control is-expanded'>
        <label class="label">
          電話番号
        </label>
        <input type="text" class="input" v-model='phone' />
      </div>
    </div>

    <div class='field' v-if='ar_type_text' >
      <div class='control is-expanded'>
        <label class="label">
          お問い合わせの種別
        </label>
        <div class="select is-fullwidth">
          <select v-model='type_text'>
            <option v-for='(x, i) in ar_type_text'
                    :key="i"
                    :value='x' v-text='x' />
          </select>

        </div>
      </div>
    </div>

    <br/>
    <div class='field'>
      <div class='control is-expanded'>
        <label class="label">
          お問い合わせ内容
          <span class="tag is-primary"
                v-text='"必須"'
                v-if='invalidp_body'
          />
        </label>

        <div v-if='institution_p' class='notification' style='margin-bottom: 8px; border: 4px solid pink; background-color: white;'>
          <h3 class='has-text-weight-bold' style='margin-bottom: 4px;'>医療機関の方へ</h3>
          <p>メドレーが掲載する病院・クリニックの情報は、厚労省及び都道府県が管理する
            <a class='has-text-weight-bold' target='_blank' href='https://www.mhlw.go.jp/stf/seisakunitsuite/bunya/kenkou_iryou/iryou/teikyouseido/index.html'>医療情報ネット</a>の情報に基づいています。</p>
          <p>メドレーの掲載内容に齟齬がある場合、まずは医療情報ネットの情報をご確認ください。</p>
          <p>医療情報ネット上の情報に齟齬がある場合は、まず、医療情報ネットに情報の修正を依頼願います。</p>
          <p>なお、医療情報ネットの内容が更新されてから、メドレーのページに情報が反映されるまで一週間程度かかります。</p>
          <a href='https://www.mhlw.go.jp/stf/seisakunitsuite/bunya/kenkou_iryou/iryou/teikyouseido/index.html' class='c-see-more' target='_blank'>医療情報ネットトップを開く</a>
        </div>

        <textarea class="textarea" v-model='body' />
      </div>
    </div>

    <br />
    <div class='field'>
      <div class='control is-expanded'>
        <button
          class='button is-medium is-primary is-fullwidth'
          v-text='"送信する"'
          :disabled='invalidp'
          @click='send'
        />
      </div>
    </div>

  </div>
</template>
