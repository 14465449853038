<script>
  import lib from './lib.vue';
  export default {
    mixins: [lib],
    props: {
    },
    data () {
      return {
        info: {
          email: {
            value: null,
            error: null,
          },
          password: {
            value: null,
            error: null,
          },
        },
      };
    },
    created () {
      var vm = this;
      vm.$watch(
        ()=>[vm.info.email.value, vm.info.password.value],
        (_to, _fr)=>{
          console.log('watch');
          vm.validate();
        },
        {deep: true},
      );
      vm.validate();
    },
    computed: {
    },
    methods: {
      validate () {
        var vm = this;
        vm.info.email.error = null;
        vm.info.password.error = null;

        if ( !vm.presentp(vm.info.email.value) ) {
          vm.info.email.error = '必須です';
        } else if (!vm.valid_emailp(vm.info.email.value)) {
          vm.info.email.error = '形式が不正です';
        }
        if ( !vm.presentp(vm.info.password.value) ) {
          vm.info.password.error = '必須です';
        }
      },
      execute () {
        var vm = this;
        vm.mhttp('post', '/me/login', {
          email: vm.info.email.value,
          password: vm.info.password.value,
        },
                 res=>{
                   var error = res.data.error;
                   if (error) {
                     var error_message = res.data.error_message;
                     vm.$dialog.alert({
                       message: error_message,
                     });
                     return;
                   }
                   vm.mset_location('/o/x');
                 });
      },
    },
  };
</script>

<template>
  <div class='columns is-4'>
    <div class='column is-6'>
      <div class='field'>
        <a href='/me/facebook_auth_req' class='button is-large is-fullwidth is-info' style='background-color: #3b5998;'>
          <i class='fab fa-facebook mr10'></i>Facebookでログイン
        </a>
      </div>

      <hr style='margin-bottom: 24px; margin-top: 24px;' />

      <p class="has-text-centered">
        医師登録は
        <a href='/me/register/'>
          こちら
        </a>
        から
      </p>

    </div>

    <div class='column is-6'>
      <b-field
        label='メールアドレス'
        :message='info.email.error'
        :type='{"is-danger": info.email.error}'
      >
        <b-input v-model='info.email.value'
        />
      </b-field>

      <b-field
        label='パスワード'
        :message='info.password.error'
        :type='{"is-danger": info.password.error}'
      >
        <b-input v-model='info.password.value'
                 type='password'
        />
      </b-field>

      <div class='field'>
        <button 
          class='button is-large is-fullwidth is-primary' 
          @click='execute'
          :disabled=' info.password.error || info.email.error '
        >
          メールアドレスでログイン
        </button>
      </div>

      <p class="has-text-centered">
        <a href='/me/reset/'>
          パスワードを忘れた方は
        </a>
      </p>
    </div>
  </div>
</template>
