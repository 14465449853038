<script>
  import lib from './lib.vue';
  export default {
    mixins: [lib],
    components: {},
    props: [],
    data () {
      return {
        offset: 0,
        page_id: null,
        page_type: null,
      };
    },
  };
</script>

<template>
  <div class='bulma'>
    <div style='position: fixed; right: 0px; bottom: 20px; xbackground-color: gray;'>

      <!-- icomoon test
      <div class='fbutton'
           style='margin-bottom: 4px;'
           @click='modal({type: "navi"});'>
        <i class='fbutton-icon fa micon-tel' />
        <br />
        目次
      </div>
      -->

      <template v-if='presentp($store.state.navi) && presentp($store.state.navip)'>
        <div class='fbutton is-hidden-tablet'
             @click='modal({type: "navi"});'>
          <i class='fbutton-icon fa fa-list-ol' />
          <br />
          目次
        </div>
      </template>

      <div class='fbutton is-hidden-mobile'
           @click='totop();'
           :class="{'fbutton-hide': $store.state.page_y_offset <= 50 ? true : false}"
      >
        <i class='fbutton-icon fa fa-chevron-up' />
        <br />
        先頭へ
      </div>
    </div>
  </div>
</template>

<style scoped>
  .c-footer.c-footer-loc {

    height: 52px;
    width: 100%;
    position: fixed;
    right: 0;
    bottom: 0;

    box-shadow: 0 1px 12px 0 gray;
    border: 0;
    background-color: white;

    height: auto;
  }
  .fbutton {
    display: block;
    margin: 0px;
    height: 48px;
    width: 48px;
    padding: 8px;

    font-size: 0.56rem;
    font-weight: bold;
    color: white;

    vertical-align: middle;

    background-color: rgba(31,45,61,0.4);

    text-align: center;
    border-radius: 8px;
    border-radius: 4px 0px 0px 4px;

    margin-right: -1px;
  }
  .fbutton.fbutton-hide {
    opacity: 0.0;

  }
  .fbutton-icon {
    font-size: 1.2rem;
  }

  @keyframes pulse_animation {
    0% { transform: scale(1); }
    30% { transform: scale(1); }
    40% { transform: scale(1.05); }
    50% { transform: scale(1); }
    60% { transform: scale(1); }
    70% { transform: scale(1.03); }
    80% { transform: scale(1); }
    100% { transform: scale(1); }
  }
  .pulse {
    animation-name: pulse_animation;
    animation-duration: 1500ms;
    transform-origin: 50% 50%;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
  .focus {
    text-shadow: 1px 1px 2px gray;
  }
  .focus-strong {
    /* no use */
    text-shadow: 1px 1px 2px gray;
    /*
    animation-name: pulse_animation;
    animation-duration: 1500ms;
    transform-origin: 50% 50%;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
     */
  }


</style>
urt
