<script>
  // 各カテゴリ(処方薬、ニュースなど)のページ毎に偏在させる検索フォームの最小要素
  import lib from './lib.vue';
  import detail10 from './search_box_10.vue';
  import detail21 from './search_box_21.vue';
  import detail22 from './search_box_22.vue';
  import detail23 from './search_box_23.vue';
  import detail30 from './search_box_30.vue';
  export default {
    components: {
      detail10,
      detail21,
      detail22,
      detail23,
      detail30,
    },
    mixins: [lib],
    props: {
      // memo.
      //
      // 0: simple-mode (detail-button not exist)
      // 1: detail-mode
      //
      // 2: detail-mode collapsed.
      // 3: detail-mode expanded.
      // toggle 1<->2 with detail-button.
      p_disp_mode: {default: 0}, //
      p_search_mode: {default: null},
      p_hide_toggle_button: {default: null},
      p_modal: {default: false},

      largep: {default: false},

      arg: {default: ()=>{return {};}},
    },
    data () {
      return {
        offset: 0,
        disp_mode: 0,
      };
    },
    created () {
      var vm = this;
      vm.disp_mode = vm.p_disp_mode;

      vm.infos = {};
      vm.infos[0] = {
        placeholder: '病名、薬名、病院名など',
        uri: q=>`/search/?${q}&mode=0`,
      };
      vm.infos[10] = {
        placeholder: '病名など',
        uri: q=>`/diseases/search/?${q}`,
        uri_top: `/diseases/`,
      };

      vm.infos[20] = {
        placeholder: '薬名、病名など',
        uri: q=>`/medicines/search/?${q}`,
      };
      vm.infos[21] = {
        placeholder: '薬名、病名など',
        uri: q=>`/medicines/search/?mode=21&${q}`,
        append_search_mode_p: true,
      };
      vm.infos[22] = {
        placeholder: '薬名など',
        uri: q=>`/medicines/search/?mode=22&${q}`,
        append_search_mode_p: true,
      };
      vm.infos[23] = {
        placeholder: '薬名、病名など',
        uri: q=>`/medicines/search/?mode=23&${q}`,
        append_search_mode_p: true,
      };

      vm.infos[30] = {
        placeholder: '病院名など',
        uri: q=>`/institutions/?${q}`,
        uri_top: '/institutions/',
      };

      vm.infos[40] = {
        placeholder: 'ニュースを検索',
        uri: q=>`/news/search/?${q}`,
        uri_top: `/news/`,
      };
    },
    mounted () {
      var vm = this;
      // vm.logd(`search_box mounted. state.search_mode: ${vm.$store.state.search_mode}. input_selected_p: ${vm.input_selected_p}`);
    },
    methods: {
      changed_search_mode_by_hand () {
        var vm = this;
        Object.keys(vm.$store.state.qs).forEach(k => {
          if (k !== 'q') {
            vm.logd('reset k:', k, ',v:', vm.$store.state.qs[k]);
            vm.$set(vm.$store.state.qs, k, "");
          }
        });
        vm.search();
      },
      search (opts = {}) {
        var vm = this;

        if (vm.search_mode === 30) {
          this.logd("search institution logic");
          var elm30 = vm.$refs['detail30'];
          if (elm30) {
            elm30.search();
            return;
          }
        }

        var xinfo = vm.info(vm.$store.state.search_mode);
        var qs2 = Object.assign({}, vm.$store.state.qs);
        delete qs2.mode;
        var paramstr = vm.hash_to_paramstr(qs2);

        if (vm.presentp(paramstr)) {
          const xhref = xinfo['uri'](paramstr);
          vm.logd('xhref:', xhref);
          window.location.href = xhref;
          return;
        }

        if (opts['enforcep']) {
          let xhref = xinfo['uri']('');
          if (vm.presentp(xinfo['uri_top'])) {
            xhref = xinfo['uri_top'];
          }

          vm.logd('xhref (enforcep):', xhref);
          window.location.href = xhref;
          return;
        }
      },
      info (xmode) {
        var vm = this;
        var xinfo = vm.infos[xmode] || vm.infos[0];
        return xinfo;
      },
      toggle_disp_mode () {
        var vm = this;
        if (vm.disp_mode === 2) {
          vm.disp_mode = 3;
        } else if (vm.disp_mode === 3) {
          vm.disp_mode = 2;
        }
      },
    },
    computed: {
      search_mode () {
        // p_search_modeを指定した場合は、そちらを優先する。
        var vm = this;
        return vm.p_search_mode || vm.$store.state.search_mode;
      },
      qs: {
        set (x) {
          var vm = this;
          vm.$set(vm.$store.state, 'qs', x);
        },
        get () {
          var vm = this;
          return vm.$store.state.qs;
        },
      },
    },
  };
</script>

<template>
  <div v-if="search_mode != -1">
    <div class="field has-addons" style="margin-bottom: 0; order: 10;">
      <div class="control" v-if="!largep && !p_search_mode">
        <div class="select">
          <select v-model="$store.state.search_mode" @change="changed_search_mode_by_hand()">
            <option :value="0">すべて</option>
            <option :value="10">病気</option>
            <option :value="20">薬</option>
            <option :value="21">- 処方薬</option>
            <option :value="22">- 市販薬</option>
            <option :value="23">- 薬解説</option>
            <option :value="30">病院</option>
            <option :value="40">ニュース</option>
          </select>
        </div>
      </div>

      <div class="control is-expanded">
        <input
          id="search_box_in_show_input"
          name="search_box_in_show_input"
          class="input"
          ref="input_q"
          :class="{'is-medium': largep}"
          :placeholder="info(search_mode).placeholder"
          v-model="qs.q"
          @keypress.enter="search({enforcep: true})" />
      </div>

      <div class="control">
        <button class="button xicon-search is-primary fa fa-search"
                :class="{'is-medium': largep}"
                @click="search({enforcep: true})" />
      </div>
    </div>

    <div v-if="(disp_mode == 2 || disp_mode == 3) && {10: 1, 21: 1, 22: 1, 23: 1}[search_mode] && !p_hide_toggle_button" class="field" style="margin: 0.5rem 0 0; order: 20;"
    >
      <div class="control is-expanded">
        <a href="#"
           @click="toggle_disp_mode();"
           class="xbutton"
           style="display: block; background-color: #f5f7fa; padding: 4px; margin: 0 -0.5rem; text-align: center;"
           :style='{"border-radius": disp_mode == 2 ? "0px 0px 6px 6px" : "0"}'
        >
          <template v-if="disp_mode == 2">
            <i class="fa fa-plus" />
            詳細条件
          </template>
          <template v-else>
            <i class="fa fa-minus" />
            詳細条件
          </template>

        </a>
      </div>
    </div>
    <div v-else-if="(disp_mode == 2 || disp_mode == 3) && search_mode != 30" style="height: 0.5rem; order: 30;" />

    <div class="detail" style="order: 40;" ref="detail" v-show="disp_mode == 1 || disp_mode == 3">
      <detail10 v-if="search_mode == 10" :p_openp="true" :p_search="search" />
      <detail21 v-else-if="search_mode == 21" :p_openp="true" :p_search="search" />
      <detail22 v-else-if="search_mode == 22" :p_openp="true" :p_search="search" />
      <detail23 v-else-if="search_mode == 23" :p_openp="true" :p_search="search" />
    </div>

    <div class="detail mt10" style="order: 40;" ref="detail" v-if="search_mode == 30 && (disp_mode == 1 || disp_mode == 2 || disp_mode == 3)">
      <!-- div style="height: 8px;" / -->
      <detail30 :p_search="search"
                :p_selected="arg[30] ? arg[30].selected : null"
                :p_conditions="arg[30] ? arg[30].conditions : null"
                :p_map_search_path="arg[30] ? arg[30].map_search_path : null"
                :p_hide_toggle_button="p_hide_toggle_button"
                :p_modal="p_modal"
                ref="detail30"
                :disp_mode="disp_mode"
                :toggle_disp_mode="toggle_disp_mode" />
    </div>
  </div>
</template>

<style scoped>
  .detail {
    padding: 0;
    margin: 0;
  }
</style>
