<script>
  // It may be better to use vuex than mixin.
  import lib from './lib.vue';

  export default {
    mixins: [lib],
    computed: {
      // ////////////////////////////// vuex from
      next_question () {
        var vm = this;
        if (vm.$store.state.sc_next_question) {
          return vm.$store.state.sc_next_question;
        }
        return {};
      },
      ans () {
        var vm = this;
        if (vm.$store.state.sc_ans) {
          vm.logd("vm.$store.state.sc_ans=", vm.$store.state.sc_ans);
          return vm.$store.state.sc_ans;
        }
        return {};
      },
      ans_new () {
        var vm = this;
        if (vm.$store.state.sc_ans_new) {
          vm.logd("vm.$store.state.sc_ans_new=", vm.$store.state.sc_ans_new);
          return vm.$store.state.sc_ans_new;
        }
        return {};
      },
      local_master () {
        var vm = this;
        if (vm.$store.state.sc_local_master) {
          vm.logd("local_master(in common)", vm.$store.state.sc_local_master);
          return vm.$store.state.sc_local_master;
        }
        return {};
      },
      mst_age () {
        var vm = this;
        if (vm.$store.state.sc_mst_age) {
          return vm.$store.state.sc_mst_age;
        }
        return {};
      },
      ar_age () {
        var vm = this;
        if (vm.$store.state.sc_ar_age) {
          return vm.$store.state.sc_ar_age;
        }
        return [];
      },
      important_diseases() {
        var vm = this;
        if (vm.$store.state.sc_important_diseases) {
          vm.logd('vm.$store.state.sc_important_diseases=', vm.$store.state.sc_important_diseases);
          return vm.$store.state.sc_important_diseases;
        }
        return {};
      },
      tmp_opti: {
        get () {
          var vm = this;
          if (vm.$store.state.sc_tmp_opti) {
            return vm.$store.state.sc_tmp_opti;
          }
          return {};
        },
        set (x) {
          var vm = this;
          vm.$set(vm.$store.state, 'sc_tmp_opti', x);
        }
      },
      // ////////////////////////////// vuex to

      // TODO: ans_yes_newに置き換えて削除
      ans_yes () {
        var vm = this;
        var ret = [];
        Object.keys(vm.ans).forEach(sid => {
          if (sid.match(/^(malep|adult|child|old)/)) {
            return;
          }
          if (vm.ans[sid][0] === '1') {
            ret.push(sid);
          } else {
          }
        });
        return ret;
      },
      ans_yes_new () {
        var vm = this;
        var ret = [];
        if (!vm.ans_new.answers) {
          return ret;
        }
        vm.ans_new.answers.forEach(v => {
          if (v.yes === '1') {
            ret.push(v.id);
          }
        });
        return ret;
      },
      // TODO: ans_no_newに置き換えて削除
      ans_no () {
        var vm = this;
        var ret = [];
        Object.keys(vm.ans).forEach(sid => {
          if (sid.match(/^(malep|adult|child|old)/)) {
            return;
          }
          if (vm.ans[sid][0] === '0') {
            ret.push(sid);
          }
        });
        return ret;
      },
      ans_no_new () {
        var vm = this;
        var ret = [];
        if (!vm.ans_new.answers) {
          return ret;
        }
        vm.ans_new.answers.forEach(v => {
          if (v.yes === '0') {
            ret.push(v.id);
          }
        });
        return ret;
      },
      ans_special () {
        var vm = this;
        var ret = [];
        Object.keys(vm.ans).forEach(sid => {
          if (sid.match(/^(malep|age|adult|child|old)/)) {
            ret.push(sid);
          }
        });
        return ret;
      },
    },
    methods: {
      poke_tmp_opti (xopti_base32) {
        var vm = this;
        vm.$set(vm.$store.state.sc_tmp_opti, xopti_base32, !(vm.$store.state.sc_tmp_opti[xopti_base32]));
        vm.logd("vm.$store.state.sc_tmp_opti=", vm.$store.state.sc_tmp_opti);
        vm.logd("[typeof] vm.$store.state.sc_tmp_opti=", typeof vm.$store.state.sc_tmp_opti);
      },
      reload () {
        var vm = this;
        var ar_ans = [];
        var symptoms = vm.ans_new.answers.map((a) => {
          var sid = a.id;
          var yes = a.yes;
          var main_v = a.main;
          var main = "";
          if (!!main_v) {
            if (main_v instanceof Array) {
              if (main_v.length === 0) {
                main = "z";
              } else {
                main_v.forEach(v => {
                  main += `${v}`;
                });
              }
            } else {
              if (Object.keys(main_v).length === 0) {
                main = "z";
              } else {
                Object.keys(main_v).forEach(v => {
                  if (main_v[v]) {
                    main += `${v}`;
                  }
                });
              }
            }
          }

          var sub_v = a.sub;
          var sub = "";
          if (!!sub_v) {
            if (sub_v instanceof Array) {
              if (sub_v.length === 0) {
                sub = "z";
              } else {
                sub_v.forEach(v => {
                  sub += `${v}`;
                });
              }
            } else {
              if (Object.keys(sub_v).length === 0) {
                sub = "z";
              } else {
                Object.keys(sub_v).forEach(v => {
                  if (sub_v[v]) {
                    sub += `${v}`;
                  }
                });
              }
            }
          }
          return [sid, yes, main, sub].filter(Boolean).join('_');
        });

        symptoms = symptoms.join('/');
        var newpathname2 = `/symptoms/${symptoms}/`;
        if (!!vm.ans_new.age) {
          var age = vm.ans_new.age;
          newpathname2 = newpathname2 + age + "/";
        }
        if (!!vm.ans_new.gender) {
          var gender = vm.ans_new.gender;
          gender = gender == 1 ? "malep" : "malep_0";
          newpathname2 = newpathname2 + gender + "/";
        }
        location.pathname = newpathname2;
      },
      add_symptom (sid, value = '1') {
        var vm = this;
        vm.ans[sid] = [value];
        vm.ans_new.answers.push({ id: sid, yes: value });
        vm.reload();
      },
      set_symptom (sid, i, value) {
        // answer detail_question.
        var vm = this;
        // チェック無しで「選択した内容で進む」を押した場合は未回答扱い
        if (Object.values(value).indexOf(true) < 0) {
          value = {z: 1};
        }
        // TODO: テスト後、ans_new -> ansに置き換え
        vm.ans[sid][i] = value;
        // 末尾の要素が現在回答中の症状データ
        var elem = vm.ans_new.answers[vm.ans_new.answers.length - 1];
        if (i === 1) { // main
          elem.main = value;
        } else { // sub
          elem.sub = value;
        }
        vm.reload();
      },
      sym_info (sid, key) {
        var vm = this;
        var master = vm.local_master[sid];
        if (!master) {
          return 'unknown';
        }

        return master[key];
      },
    },
  };
</script>
