<script>
var ScAskQuestionButton = {
  mixins: [scCommon],
  props: ["p_sid", "p_qid", "p_val", "p_text"],
  template: `
<div class='o-symptom-ask-button-holder'>
  <button class="button is-fullwidth o-symptom-button is-size-5 is-long"
          style='color: #1976d2; height: 46px; border-width: 1px;'
          @click='set_symptom(p_sid, p_qid, p_val)'
  >
    {{p_text}}
  </button>
</div>
`
};

import scCommon from "./sc_common.vue";
export default {
  mixins: [scCommon],
  components: {
    ScAskQuestionButton
  },
  created() {
    var vm = this;
    window.d_sc_ask = vm;
  }
};
</script>
<template>
  <div>
    <div style="padding: 8px;">
      <div class="columns is-multiline is-0">
        <div
          v-for="(opt, opti) in next_question.data.detail_question.opts"
          :key="opti"
          class="column is-6"
        >
          <div @click="poke_tmp_opti(to_base32(opti))">
            <input :id="`sc_ask_opt_${opti}`" type="checkbox" :checked="tmp_opti[to_base32(opti)]" />
            <label for="`sc_ask_opt_${opti}`" class="o-check-box fw-normal" v-text="opt.text" />
          </div>
        </div>
      </div>
    </div>
    <div class="columns is-1 is-mobile">
      <div class="column is-6">
        <sc-ask-question-button
          :p_sid="next_question.data.sid"
          :p_qid="next_question.data.i"
          :p_val="tmp_opti"
          :p_text='"選択して次へ"'
        />
      </div>
      <div class="column is-6">
        <sc-ask-question-button
          :p_sid="next_question.data.sid"
          :p_qid="next_question.data.i"
          :p_val="{z: 1}"
          :p_text='"当てはまらない"'
        />
      </div>
    </div>
  </div>
</template>
