<script>
  import institutionCard from './institution_card.vue';
  import axios from 'axios';

  const DEFAULT_DISTANCE = 1000;  // 病院検索の範囲
  const DEFAULT_LATLNG_OFFSET = 0.01; // 東西、南北およそ1kmに相当

  export default {
    components: {
      institutionCard
    },
    props: [
      'currentInstitutions',
      'currentSelected',
      'totalInstCount'
    ],
    data: function () {
      return {
        size_list: [20, 50, 100, 200],
        markers: [],
        center: null,
        institutions: [],
        distance: DEFAULT_DISTANCE,
        gmap: null,
        selected: {},
        total: 0
      };
    },
    created: function () {
      this.selected = JSON.parse(this.currentSelected);
      this.total = this.totalInstCount;
    },
    mounted: function () {
      var vm = this;
      if (vm.$session.get('users_inst_gmap_location_params') === vm.createLocationParams()) {
        vm.center = vm.$session.get('users_inst_gmap_center');
        vm.distance = vm.$session.get('users_inst_gmap_distance');

        vm.gmap = new google.maps.Map(document.getElementById('institution_map'), {
          center: vm.center,
          zoom: vm.$session.get('users_inst_gmap_zoom'),
          clickableIcons: false
        });
      } else {
        vm.gmap = new google.maps.Map(document.getElementById('institution_map'), {
          zoom: 5,
          clickableIcons: false
        });
        vm.$session.set('users_inst_gmap_location_params', vm.createLocationParams());
        vm.$session.remove('users_inst_gmap_center');
        vm.$session.remove('users_inst_gmap_zoom');
        vm.$session.remove('users_inst_gmap_distance');
      }

      vm.institutions = JSON.parse(vm.currentInstitutions);
      if (vm.institutions.length < 1 && !vm.center && vm.createLocationParams() === '') {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            function (position) {
              const coords = position.coords;
              vm.center = { lat: coords.latitude, lng: coords.longitude };
              vm.setEventLisner();
            },
            function (error) {
              switch (error.code) {
                case 1:
                  alert("位置情報の利用が許可されていません");
                  break;
                case 2:
                  alert("現在位置が取得できませんでした");
                  break;
                case 3:
                  alert("タイムアウトになりました");
                  break;
                default:
                  alert("その他のエラー(エラーコード:" + error.code + ")");
                  break;
              }
            });
          // Geolocation APIに対応していない
        } else {
          alert("この端末では位置情報が取得できません");
        }
      } else if (vm.center) {
        vm.setEventLisner(false);
      } else {
        vm.setInstitutions({ institutions: vm.institutions, total: vm.total });
        google.maps.event.addListenerOnce(vm.gmap, 'idle', function () {
          vm.gmap.addListener('zoom_changed', function () {
            vm.reloadInstitutions();
          });
        });
        vm.gmap.addListener('dragend', function () {
          vm.reloadInstitutions();
        });
      }
    },
    methods: {
      createLocationParams: function () {
        return this.selected.pref + this.selected.city + this.selected.town + this.selected.line.value + this.selected.station.value;
      },
      setEventLisner: function (bound = true) {
        var vm = this;
        axios.get('/api/u/institution_search/latlng?' + vm.createParams())
             .then((res) => {
               vm.setInstitutions(res.data, bound);
               vm.gmap.addListener('zoom_changed', function () {
                 vm.reloadInstitutions();
               });
               vm.gmap.addListener('dragend', function () {
                 vm.reloadInstitutions();
               });
             }).catch((res) => {
               alert("通信に失敗しました");
             });
      },
      createParams: function () {
        var vm = this;
        var query = '';
        if (vm.selected.field.value) {
          query += `&field=${vm.selected.field.value}`;
        }
        if (vm.selected.symptom.value) {
          query += `&symptom=${vm.selected.symptom.value}`;
        }
        if (vm.selected.disease.value) {
          query += `&disease=${vm.selected.disease.value}`;
        }
        if (vm.selected.inst_type) {
          query += `&inst_type=${vm.selected.inst_type}`;
        }
        if (vm.selected.specialist) {
          query += `&specialist=${vm.selected.specialist}`;
        }
        if (vm.selected.specialty_outpatient) {
          query += `&specialty_outpatient=${vm.selected.specialty_outpatient}`;
        }
        if (vm.selected.preventive_inoculation) {
          query += `&preventive_inoculation=${vm.selected.preventive_inoculation}`;
        }
        if (vm.selected.q) {
          query += `&q=${vm.selected.q}`;
        }
        if (vm.$session.get('users_inst_gmap_size')) {
          vm.selected.size = vm.$session.get('users_inst_gmap_size');
          query += `&size=${vm.$session.get('users_inst_gmap_size')}`;
        } else if (vm.selected.size) {
          query += `&size=${vm.selected.size}`;
        }
        if (0 < vm.selected.availabilities.length) {
          vm.selected.availabilities.forEach((k) => {
            query += `&availabilities[]=${k}`;
          });
        }
        if (!vm.distance) {
          vm.distance = DEFAULT_DISTANCE;
        }
        return `latitude=${vm.center.lat}&longitude=${vm.center.lng}&distance=${vm.distance}${query}`;
      },
      reloadInstitutions: function (map) {
        var vm = this;
        var center = vm.gmap.getCenter();
        vm.center = { lat: center.lat(), lng: center.lng() };
        var bounds = vm.gmap.getBounds();
        this.$session.set('users_inst_gmap_size', vm.selected.size);
        this.$session.set('users_inst_gmap_zoom', vm.gmap.getZoom());
        this.$session.set('users_inst_gmap_center', vm.center);

        if (!vm.center) {
          return;
        }
        vm.distance = Math.floor(google.maps.geometry.spherical.computeDistanceBetween(bounds.getNorthEast(), bounds.getSouthWest()) * 0.8 / 2);
        this.$session.set('users_inst_gmap_distance', vm.distance);

        axios.get('/api/u/institution_search/latlng?' + vm.createParams())
             .then((res) => {
               vm.setInstitutions(res.data, false);
             })
             .catch((res) => {
               alert("通信に失敗しました");
             });
      },
      setInstitutions: function (data, bound = true) {
        var vm = this;
        var currentInfoWindow = null;
        var bounds = new google.maps.LatLngBounds();
        vm.total = data.total;
        vm.institutions = data.institutions.filter(inst => inst.location != null);

        vm.markers.forEach((k) => {
          k.setMap(null);
        });

        vm.institutions.forEach((k) => {
          var marker = new google.maps.Marker({
            position: { lng: k.location[0], lat: k.location[1] },
            map: vm.gmap
          });
          if (bound) {
            bounds.extend(marker.position);
          }
          vm.markers.push(marker);

          var content = '<h2 class="has-text-weight-bold">' + k.name + '</h2>';
          content += '<p class="mt5 mb5"><i class="icon-access fc-bg500 mr5"></i>〒 ' + k.postal_code + ' ' + k.address + '</p>';
          content += '<p class="mt5"><i class="icon-tel fc-bg500 mr5"></i>' + k.tel_day + '</p>';
          content += '<p class="mt15 mb5"><a href="/institutions/' + k.id + '/" target="_blank">' + '施設の情報を確認する</a></p>';

          var infoWindow = new google.maps.InfoWindow({
            content: content,
            maxWidth: 300
          });
          marker.addListener('click', () => {
            if (currentInfoWindow) {
              currentInfoWindow.close();
            }
            infoWindow.open(vm.gmap, marker);
            currentInfoWindow = infoWindow;
          });
        });
        if (bound) {
          if(this.institutions.length === 0 && this.center){
            // LatLngBoundsが初期状態のままの場合は、現在地周辺を表示領域とするようLatLngBoundsを設定する。
            bounds = new google.maps.LatLngBounds(
              new google.maps.LatLng(this.center.lat-DEFAULT_LATLNG_OFFSET, this.center.lng-DEFAULT_LATLNG_OFFSET),
              new google.maps.LatLng(this.center.lat+DEFAULT_LATLNG_OFFSET, this.center.lng+DEFAULT_LATLNG_OFFSET)
            );
          }

          // boundsはzoomよりも優先されるため、zoomではなくboundsを操作して表示領域を制御する。
          // bounds が狭すぎる場合は広げる
          const bounds_span = bounds.toSpan();
          if(bounds_span && bounds_span.lat() < DEFAULT_LATLNG_OFFSET || bounds_span.lng() < DEFAULT_LATLNG_OFFSET){
            const center = bounds.getCenter();
            bounds = new google.maps.LatLngBounds(
              new google.maps.LatLng(center.lat()-DEFAULT_LATLNG_OFFSET, center.lng()-DEFAULT_LATLNG_OFFSET),
              new google.maps.LatLng(center.lat()+DEFAULT_LATLNG_OFFSET, center.lng()+DEFAULT_LATLNG_OFFSET)
            );
          }
          vm.gmap.fitBounds(bounds);
        }
      }
    }
  };
</script>

<template>
  <div>
    <div class="l-not-xpad">
      <div class="w100p h500 mt20 mb10" id="institution_map" />
    </div>

    <div>
      <p class="fs-x-s-s mt10">
        地図に表示されているエリア内で病院・クリニックを検索します。<br> ※表示位置が必ずしも正確でない場合があります、最新の情報は各医療機関へお問い合わせ下さい。
      </p>
    </div>

    <div class="columns is-mobile" style="align-items: center;">
      <div class="column is-narrow">
        <div class="select">
          <select @change="reloadInstitutions()" v-model="selected.size">
            <option v-for="(inst_size, i) in size_list" :value="inst_size" :key="i">
              {{ inst_size }}件表示
            </option>
          </select>
        </div>
      </div>
      <div class="column">
        <!-- eslint-disable -->
        <span class="fc-r300">{{ total }}</span>件中 1 - {{ total < selected.size ? total : selected.size }}件
        <!-- eslint-enable -->
      </div>
    </div>
    <div class="l-not-xpad">
      <ul>
        <li v-for="(inst, i) in institutions" class="c-search-item" :key="i">
          <institution-card :institution="inst" />
        </li>
      </ul>
    </div>
  </div>
</template>
