<script>
// inject some global sc data into vuex.
import lib from "./lib.vue";
export default {
  mixins: [lib],
  props: {
    next_question: {
      default: () => {
        return {};
      }
    },
    ans: {
      default: () => {
        return {};
      }
    },
    ans_new: {
      default: () => {
        return {
          gender: null,
          age: null,
          answers: []
        };
      }
    },
    local_master: {
      default: () => {
        return {};
      }
    },
    important_diseases: {
      default: () => {
        return {};
      }
    }
  },
  created() {
    var vm = this;
    vm.$store.state.sc_next_question = vm.next_question;
    vm.$store.state.sc_ans = vm.ans;
    vm.$store.state.sc_ans_new = vm.ans_new;
    vm.$store.state.sc_local_master = vm.local_master;
    vm.$store.state.sc_mst_age = {};
    vm.$store.state.sc_ar_age = [];
    vm.$store.state.sc_important_diseases = vm.important_diseases;

    // FIXME: keyが以前のままなので改修後の適切な形に
    [
      { key: "age10", name: "小児" },
      { key: "age60", name: "大人" },
      { key: "age90", name: "高齢者" }
    ].forEach(x => {
      vm.$store.state.sc_mst_age[x.key] = x;
      vm.$store.state.sc_ar_age.push(x.key);
    });

    var tmp_tmp_opti = {};
    if (
      vm.next_question &&
      vm.next_question.data &&
      vm.next_question.data.detail_question &&
      vm.next_question.data.detail_question.opts
    ) {
      for (
        var i = 0;
        i < vm.next_question.data.detail_question.opts.length;
        i++
      ) {
        tmp_tmp_opti[vm.to_base32(i)] = false;
      }
    }
    vm.$set(vm.$store.state, "sc_tmp_opti", tmp_tmp_opti);
  }
};
</script>
<template>
  <div style="display: none;">init node.</div>
</template>
