<script>
/* scroll 追従用 */
import naviBox from './navi_box.vue';
import lib from './lib.vue';
export default {
  mixins: [lib],
  components: {
    naviBox
  },
  data () {
    return {
      style: {},
    };
  },
  mounted () {
    var vm = this;
    // buggyp?
    // var xanchor_dom = document.getElementById('anchor-aside-bottom');
    // if (xanchor_dom) {
    //   window.addEventListener('scroll', () => {
    //     var xanchor = xanchor_dom.offsetTop + 320;
    //     var xscroll = window.pageYOffset;
    //     this.logd(`xanchor: ${xanchor}. xscroll: ${xscroll}`);
    //     if (xanchor < xscroll) {
    //       vm.style = { position: 'fixed', top: '102px', width: '300px' };
    //     } else {
    //       vm.style = {};
    //     }
    //     this.logd('style:', vm.style);
    //   }, {
    //     passive: true,
    //     capture: true
    //   });
    // }
  },
};
</script>
<template>
<nav id="toc">
  <div>
    <span class="c-aside-h1">
      目次
    </span>
    <navi-box :neatp="true" />
  </div>
</nav>
</template>

