<script>
  import lib from './lib.vue';
  import mcheck from './mcheck.vue';
  export default {
    mixins: [lib],
    components: {
      mcheck,
    },
    props: {
      'p_search': {},
    },
    data () {
      return {
        cond_body: [],
        cond_field: [],
      };
    },
    created () {
      var vm = this;
      window.dsbdd = vm;

      // 親に任せる。
      vm.qinfo_list = [
        {id: 'q', name: 'キーワード'},
        {id: 'qbody', name: '部位'},
        {id: 'qfield', name: '診療科'},
        {id: 'qspecialp', name: '特定疾患'}, // ??
        // {id: 'qtopicp', name: 'まとめ記事'}, // ??
      ];

      vm.qinfo = {};
      vm.qinfo_list.forEach(x=>{
        vm.qinfo[x['id']] = x;
      });

      if (vm.mode_from_uri() === 10) {
        var qh = vm.$qs.parse(location.search);
        Object.keys(vm.qinfo).forEach(x=>{
          vm.$set(vm.$store.state.qs, x, qh[x] || '');
        });
      }
    },
    methods: {
      inputed (place) {
      },
      search () {
        // nop.
        // 条件換えるたびに検索するのは鬱陶しいので、一旦止める。
      },
      clear_qs () {
        var vm = this;
        var x = {};
        vm.qinfo_list.forEach(i=>{
          x[i.id] = '';
        });
        vm.$set(vm.$store.state, 'qs', x);
      },
    },
  };
</script>

<template>
  <div class='bulma' style='margin: 0; padding: 0;'>
    <div ref='detail'
         style='overflow: hidden; margin: 16px 0 0; padding: 0;'
    >
      <template v-if='presentp($store.state.search_condition)'>
        <p v-text='qinfo["qbody"]["name"] + ""' />
        <div class='field'>
          <div class='control is-expanded'>
            <div class="select is-fullwidth">
              <select v-model='$store.state.qs.qbody'>
                <option value=''>--</option>
                <option v-for='x in $store.state.search_condition.disease_body' :value='x.id' :key='x.id'>
                  {{x.name}}
                </option>
              </select>
            </div>
          </div>
        </div>

        <div style='height: 8px;' />

        <p v-text='qinfo["qfield"]["name"] + ""' />
        <div class='field'>
          <div class='control is-expanded'>
            <div class="select is-fullwidth">
              <select v-model='$store.state.qs.qfield'>
                <option value=''>--</option>
                <option v-for='x in $store.state.search_condition.disease_field' :value='x.id' :key='x.id'>
                  {{x.name}}
                </option>
              </select>
            </div>
          </div>
        </div>

        <div style='height: 8px;' />

      </template>

      <mcheck p_label='特定疾患(指定難病)' v-model='$store.state.qs.qspecialp' />

      <div style='height: 32px;' />

      <div class='field'>
        <div class="control has-text-centered">

          <button class='button is-expanded xis-large' @click='clear_qs'>
            全てクリア
          </button>
          &nbsp;&nbsp;
          <button
              class='button is-expanded xis-large is-primary xicon-search'
              @click='p_search({enforcep: true})'>
            <i class='fa fa-search'></i>
            &nbsp;
            検索
          </button>
        </div>
      </div>
      <div style='hight: 8px;' />
    </div>
  </div>
</template>

<style scoped>
  p {
    font-weight: bold;
    margin: 0;
  }
  .card {
    display: block;
  }
  .card.closedp {
    display: none;
  }
  .card-content {
    padding: 12px;
  }
</style>
