<script>
import lib from './lib.vue';
export default {
  mixins: [lib],
  computed: {
    navi () {
      return this.$store.state.navi;
    },
    navi_columns () {
      var vm = this;
      return vm.navi.hs.filter((h) =>  ('link' in h) && ('title' in h) && !h.skipp);
    }
  },
  props: {
    p_height: { default: null },
    neatp: { default: false },
  },
  data () {
    return {
      medley_navi: null,
      height: null,
    };
  },
  mounted () {
    var vm = this;
    vm.height = vm.p_height ? `${vm.p_height}px` : 'auto';
    if (vm.presentp(window.medley_navi) && window.medley_navi.length > 1) {
      // window.medley_naviにerbで情報をセットしてる。
      // とりあえず、app/views/users/shared/_module.html.erb でscriptタグを吐き出すことで実現してる。
      // TODO: rubyとjsとのやり取りをapiを使わずやる方法を整理する必要ある。
      vm.medley_navi = window.medley_navi.map(x=>{
        x['activep'] = x['action_name'] === window.medley_navi_active_action_name;
        return x;
      });
    }
  },
};
</script>
<template>
<div :class="{card: !neatp}"
     style="width: 100%; height: 100%;"
     v-if="navi">
  <template v-if="neatp">
    <div class="is-black is-size-6 has-text-weight-bold"
         style="padding: 8px 0;">
      {{navi.title}}
    </div>
    <ul class="is-black is-size-6">
      <li v-for="(h, i) in navi_columns"
          :key="i"
          class="join-by-border"
          :class="{'is-blue': h.linkp}"
          style="padding: 8px 0;"
          >
        <a :href="h.link" @click="modal(null);" style="display: block; width: 100%;">
          {{h.title}}
        </a>
      </li>
    </ul>
  </template>
  
  <template v-else>
    <div class="card-header card-head-m">
      <div class="card-header-title">
        {{navi.title}}
      </div>
    </div>
    <div class="card-body" style="padding: 20px; overflow: scroll; max-height: calc(100vh - 200px);" :xstyle="{'max-height': height}">
      <table class="table xis-bordered is-fullwidth" style="font-size: 0.8rem; margin: 0; border-top: 1px solid #e0e6ed; border-bottom: 1px solid #e0e6ed;">
        <tbody>
          <tr v-for="(h, i) in navi_columns" :key="i">
            <td>
              <a :href="h.link" @click="modal(null);" style="display: block; width: 100%;" :style="{color: h.linkp ? '#1976d2' : 'black'}">
                {{h.title}}
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    
    <footer class="card-foot" style="padding: 8px 12px;" v-if="navi.aprev || navi.anext">
      <div style="width: 98%; margin: auto;">
        <div class="tabs tabs-m xis-info is-toggle is-fullwidth is-small"
             v-if="navi.aprev || navi.anext">
          <ul>
            <li :class="{'is-active': navi.aprev}" style="border-right: 2px solid white;">
              <a :href="navi.aprev ? navi.aprev.link : '#'">
                <span class="icon is-small"><i class="fa fa-chevron-left"></i></span>
                <span>戻る</span>
              </a>
            </li>
            <li :class="{'is-active': navi.anext}">
              <a :href="navi.anext ? navi.anext.link : '#'">
                <span>続き</span>
                <span class="icon is-small"><i class="fa fa-chevron-right"></i></span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  </template>
</div>
</template>

<style scoped>
.join-by-border {
    border-top: 1px solid #e0e6ed;
}
.join-by-border:last-child {
    border-bottom: 1px solid #e0e6ed;
}
</style>
