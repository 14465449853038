<script>
  /* 複数コンポーネントで共通に使うvuexのデータを初期化するモジュール */
  import lib from './lib.vue';
  export default {
    mixins: [lib],
    props: {
      ruby_env: {
        default: ()=>{return {};},
      }
    },
    created () {
      var vm = this;

      vm.$set(vm.$store.state, 'ruby_env', vm.ruby_env);
      if (!vm.ie11hell_p && vm.ruby_env.developmentp) {
        vm.logd_enable();
      }

      const mode = vm.mode_from_uri();
      vm.$set(vm.$store.state, 'search_mode', mode === -1 ? 0 : mode);

      // init param from uri.
      var qh = vm.$qs.parse(location.search);
      if (mode === 30) {
        // 病院検索のときの特殊処理。uri pathnameのpref_xxなど。
        var pn = location.pathname;
        pn = pn.replace(/\/institutions\//, '');
        var pns = pn.split('/');
        pns.forEach(p=>{
          var m = p.match(/(.+)_([^_]+)/);
          if (m) {
            var k = m[1];
            var v = decodeURI(m[2]);
            this.logd("k: ", k);
            if (k === 'st') {
              k = 'station';
            }
            qh[k] = v;
          }
        });
      }

      vm.$set(vm.$store.state, 'qh', qh);
      vm.$set(vm.$store.state, 'qs', {});
      vm.$set(vm.$store.state.qs, 'q', qh['q']);

      vm.$set(vm.$store.state, 'qs', qh);

      // safari's scrollTop is buggy...
      // https://bugs.chromium.org/p/chromium/issues/detail?id=345592
      // scroll position.
      vm.page_scroll_count = 0;
      vm.$set(vm.$store.state, 'page_scroll_count', 0);
      vm.$set(vm.$store.state, 'page_y_offset', window.pageYOffset);
      window.addEventListener('scroll', ()=>{
        vm.$store.state.page_y_offset_prev = vm.$store.state.page_y_offset;
        vm.$store.state.page_y_offset = window.pageYOffset;
        vm.page_scroll_count += 1;
        if (vm.page_scroll_count % 10 === 0 || vm.page_scroll_count === 5) {
          vm.$store.state.page_scroll_count = vm.page_scroll_count;
          // clear tooltip when scrolled.
          vm.tooltip(null);
        }
      }, {
        passive: true,
        capture: true},
      );

      var xpath = window.location.pathname;
      var navip = (pp)=>{
        vm.$set(vm.$store.state, 'navip', pp);
      };

      // white list.
      if (null) {
      } else if (xpath.match(new RegExp('^/(medicines/(otc))/manufacturer/?'))) {
        navip(false);
      } else if (xpath.match(new RegExp('^/diseases/[0-9a-zA-Z]{24}/questions.+'))) {
        navip(false);
      } else if (xpath.match(new RegExp('^/(diseases|news|institutions|medicines/(article))/[0-9a-zA-Z]{24}/?'))) {
        navip(true);
      } else if (xpath.match(new RegExp('^/(medicines/(prescription|article|otc))/[0-9a-zA-Z]{12}/?'))) {
        navip(true);
      } else if (xpath.match(new RegExp('^/diseases/topics/[0-9a-zA-Z]{24}/details.*'))) {
        navip(true);
      } else {
        navip(false);
      }

      // init toc info.
      // 検索のメタ情報などを取得して定数などを構築しておく。
      // erbのinject_jsの仕組みで構築してもいいかもしれない。
      // =>
      // httpのgzip圧縮がhtmlに埋め込んだjsonに効かなそうなので、apiで実装したほうがまだましそう。
      // すべてのページで毎回このapiを呼び出すのが気持ち悪い。
      // ブラウザのキャッシュ領域とか使うのも気が引けるしまあいいか。。
      vm.mhttp('get', '/api/u/search_condition_master/', {},
               res=>{
                 var x = {};

                 x.disease_body = res.data.item['disease_body'];
                 x.disease_field = res.data.item['disease_field'];

                 // ////////////////////////////
                 // ES6 support common-lisp-let block or something? ruby's begin block kind of stuff.
                 // simple block like {} maybe confusing with hash...
                 if (true) {
                   let all_category = res.data.item['prescription_category'];
                   let category = {};
                   all_category.forEach(x=>{
                     if (x.id.length === 1) {
                       category['root'] = category['root'] || [];
                       category['root'].push(x);
                     } else if (x.id.length > 1) {
                       let parent = x.id.substring(0, x.id.length - 1);
                       category[parent] = category[parent] || [];
                       category[parent].push(x);
                     }
                   });
                   x.prescription_category = category;
                 }
                 if (true) {
                   let all_category = res.data.item['otc_category'];
                   let category = {};
                   all_category.forEach(x=>{
                     if (x.id.length === 1) {
                       category['root'] = category['root'] || [];
                       category['root'].push(x);
                     } else if (x.id.length === 4) { // A456 kinda stuff.
                       let parent = x.id.substring(0, 1);
                       category[parent] = category[parent] || [];
                       category[parent].push(x);
                     } else {
                       this.logd("ridicurous. x.id:", x.id);
                     }
                   });
                   x.otc_category = category;
                 }
                 if (true) {
                   x.medicine_category = res.data.item['medicine_category'];
                 }
                 vm.$set(vm.$store.state, 'search_condition', x);
               });
    },
    mounted () {
      // ////////////////////////////
      // headlines information.
      var vm = this;
      // TODO: dispatch with uri

      // ios safariのタッチイベントリスナが変なので、少しコードが変。
      // https://www.htmlhifive.com/conts/web/view/library/ios-click-event
      var app = document.getElementById('ghost-app');
      if (app) {
        // bodyの子要素にハンドラ設定すると、bodyのハンドラも反応する？
        app.addEventListener('click', ()=>{
          vm.tooltip(null);
        }, {
          passive: true,
          capture: true},
        );
      }

      // const cards = document.getElementsByClassName('item-card');
      // Array.from(cards, card => {
      //   const url = card.getAttribute('data-link');
      //   card.addEventListener('click', evt => {
      //     // aタグをクリックしても2重で開かれないようにする
      //     evt.preventDefault();
      //     if (evt.shiftKey) {
      //       // shiftキーとマウスクリックで新しいwindowで開くようにする
      //       window.open(url).focus();
      //     } else if (evt.metaKey || evt.ctrlKey || card.hasAttribute('data-is-blank')) {
      //       // cmd(ctrl)キーとマウスクリックで別タブで開くようにする
      //       window.open(url, '_blank');
      //     } else {
      //       window.location.href = url;
      //     }
      //   });
      // });

      var xnavi = {};
      // リファクタ途中。
      var xreturn = ()=>{
        vm.$set(vm.$store.state, 'navi', xnavi);
      };

      const h1 = document.querySelector('.c-h1') || document.querySelector('h1');
      if (h1 && vm.presentp(h1.textContent)) {
        if (!vm.presentp(h1.id)) {
          h1.id = 'h1id_automatic';
        }
        xnavi.title = h1.textContent;
      } else {
        xnavi.title = '見出し';
      }

      // 構造化が甘いので、解析しづらい。
      // ベストエフォートである程度頑張る。

      // app/views/users/shared/_module.html.erb のnavijsのようなアプローチを
      // 広げていけば、もっとクレバーで確実なnaviが出来そう。

      var xhs = [];
      let i = 0;
      let h2s = [];

      if (xhs.length === 0) {
        var a_s = document.querySelectorAll('li.ref-feature-page-index>a');
        if (a_s.length > 0) {
          a_s = Object.keys(a_s).map(key=>a_s[key]);
          a_s.forEach(a=>{
            vm.logd(a.href);
            vm.logd(a.textContent);
            // linkpはアンカーでなく遷移リンクなので表示を切り替えるため
            xhs.push({title: a.textContent, link: a.href, linkp: true});
          });
          xnavi.hs = xhs;
          xreturn();
          return;
        }
      }
      if (h2s.length === 0) {
        h2s = document.querySelectorAll('h2.c-h2');
      }
      if (h2s.length === 0) {
        h2s = document.querySelectorAll('h2.o-h2-item');
      }
      if (h2s.length === 0) {
        h2s = document.querySelectorAll('.o-item-content h2');
      }
      if (h2s.length === 0) {
        h2s = document.querySelectorAll('.c-content h2');
      }
      if (h2s.length === 0) {
        h2s = document.querySelectorAll('.l-box__main--mobilepadding h2');
      }
      if (h2s.length === 0) {
        h2s = document.querySelectorAll('.l-box__main h2');
      }
      if (h2s.length === 0) {
        h2s = document.querySelectorAll('#ghost-app-main h2');
      }
      if (h2s.length === 0) {
        h2s = document.querySelectorAll('#ghost-app-main h3'); // news.
      }
      if (h2s.length === 0) {
        // h1にすがる。
        if (h1) {
          h2s = [h1];
        }
      }

      if (h2s.length === 0) {
        // xnavi.hs = [];
        // どうするか。
        // return;
        h2s = [];
      }

      h2s = Object.keys(h2s).map(key=>h2s[key]);
      vm.logd(h2s);
      xhs = h2s.map(h2=>{
        var id = h2.id;
        if (!vm.presentp(id)) {
          // 設定し忘れ。。
          id = `h2id_${i++}`;
          h2.id = id;
        }
        var h2text = h2.textContent;
        return {
          title: h2text,
          link: `#${id}`,
          skipp: (h2text === '目次'),
        };
      });

      var aprev = document.querySelector("a.ref-detail-navi-prev");
      if (aprev) {
        xnavi.aprev = {title: aprev.textContent, link: aprev.href};
      }
      var anext = document.querySelector("a.ref-detail-navi-next");
      if (anext) {
        xnavi.anext = {title: anext.textContent, link: anext.href};
      }
      var xuri = window.location.toString();
      var match = xuri.match(new RegExp('/diseases/([a-zA-Z0-9]+)/details/.+'));
      if (match) {
        xnavi.atotop = {title: '階層トップ', link: `/diseases/${match[1]}/details/`};
      }

      if (!vm.presentp(xhs) || xhs.length <= 0) {
        // 常にnaviを表示したい畑。
        // TODO: 整理する。
        // xhs生成も、/app/views/users/shared/_module.html.erb のnavijsでインジェクトする感じで、
        // erbとjsをきちんとつなげる感じで実装すれば色々整理しやすそう。
        xhs = [{title: 'MEDLEYについて', link: '#ghost-app-footer'}];
      }
      xnavi.hs = xhs;
      xreturn(); return;
    },
    render (h) { return h; },
  };
</script>
