<script>
  import lib from './lib.vue';
  export default {
    mixins: [lib],
    data () {
      return {
        title: '',

        description: '',
        placeholder: '',
        message: '',

        confirmp: false,
      };
    },
    created () {
      var vm = this;
      var info = vm.$store.state.modal_opts;
      if (info.score === 1) {
        // good
        vm.title = '役に立った';
        vm.description = 'よろしければ、この情報が「役に立った」と感じた理由をお聞かせください。';
        vm.placeholder = 'この情報が「役に立った」と感じた理由をお聞かせください。';
      } else {
        // bad
        vm.title = '不十分だった';
        vm.description = '「こんな情報が欲しい」など、ご意見をお寄せいただけますと幸いです。';
        vm.placeholder = '「こんな情報が欲しい」など、ご意見をお寄せいただけますと幸いです';
      }
    },
    methods: {
      post_message () {
        var vm = this;
        if (!vm.presentp(vm.message)) {
          alert('メッセージを入力してください');
          return false;
        }
        vm.mhttp(
          'post',
          '/api/u/reactions',
          {
            type: 'mes',
            target: vm.$store.state.modal_opts.target,
            url: window.location.href,
            score: vm.$store.state.modal_opts.score,
            text: vm.message
          },
          res=>{
            vm.confirmp = true;
          },
          e=>{
            // TODO: エラーメッセージ表示
            alert('メッセージの投稿が失敗しました');
          });
      },
    },
  };
</script>
<template>
  <div v-if='!confirmp' class='card'>
    <div class='card-header'>
      <div class='card-header-title'>
        {{title}}
      </div>
      <a href="#" class="card-header-icon" @click='modal(null)'>
        <span class="icon">
          <i class="fa fa-close" aria-hidden="true"></i>
        </span>
      </a>
    </div>
    <form class="card-content">
      <div class="ta-c">
        <!-- MEDLEYの情報についてご評価いただき<br/>ありがとうございます。<br/> -->
        <span v-html="description" /><br/>
        <div class='field'>
          <textarea v-model="message" class="textarea" :placeholder="placeholder" />
        </div>
        <p class="ta-c fs-s-s mt35 mb15 fc-bg600">このフォームから送信される内容はサービス改善<br/>のためのみに利用いたします。</p>
        <p class="ta-c fs-s-s mt15 mb15 fc-r300">
          MEDLEYは医療に関する情報提供サービスです、<br/>
          病気や体調についての個別的な
          ご相談につきましては<br/>ご対応いたしかねます。ご了承下さい。
        </p>
        <button class="button is-primary is-fullwidth" @click.prevent="post_message">
          投稿する
        </button>
      </div>
    </form>
  </div>

  <div v-else class="card" id='modalcontainer'>
    <div class="card-header">
      <div class="card-header-title">
        ご意見ありがとうございました
      </div>
      <a href="#" class="card-header-icon" @click='modal(null)'>
        <span class="icon">
          <i class="fa fa-close" aria-hidden="true"></i>
        </span>
      </a>
    </div>
    <div class="card-content">
      <p class="">
        頂いたご意見には必ずスタッフが目を通し、サービス改善のためのみに利用させていただきます。<br/><br/>

        また、MEDLEYは医療に関する情報提供サービスです。<br />

        <b>病気や体調についての個別的なご相談につきましてはご対応いたしかねます。</b>
        <br/>

        あらかじめご了承下さい。
      </p>
      <div class="button is-fullwidth" @click="modal(null)">
        閉じる
      </div>
    </div>
  </div>

</template>
