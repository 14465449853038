<script>
  import scCommon from './sc_common.vue';
  export default {
    mixins: [scCommon],
    computed: {
      some_question_answered_p () {
        var vm = this;
        var somep = vm.ans_yes.some(sid => {
          var ans_len = vm.ans[sid].length;
          if (ans_len <= 1) {
            return false;
          }
          for (var i = 1; i < ans_len; i++) {
            if (vm.ans[sid][i] !== 'z') {
              return true;
            }
          }
          return false;
        });
        return somep;
      },
    },
  };
</script>
<template>
  <div>
    <template v-if="presentp(ans_yes_new)">
      <h3 class="sc-cond-h3">症状がある</h3>
      <div class="tags">
        <div class="tag" v-for="(sid, i) in ans_yes_new" :key="i">
          {{local_master[sid].name}}
        </div>
      </div>
    </template>

    <template v-if="presentp(ans_no_new)">
      <h3 class="sc-cond-h3">症状がない</h3>
      <div class="tags">
        <div class="tag" v-for="(sid, i) in ans_no_new" :key="i">
          {{local_master[sid].name}}
        </div>
      </div>
    </template>

    <template v-if="presentp(ans_special)">
      <h3 class="sc-cond-h3">条件</h3>
      <div class="tags">
        <div class="tag"
             v-for="(sid, i) in ans_special"
             :key="i"
        >
          <span v-if='sid === "malep" && ans["malep"][0] === "1"' v-text='"男性"' />
          <span v-else-if='sid === "malep" && ans["malep"][0] === "0"' v-text='"女性"' />
          <span v-else-if='sid === "malep" && ans["malep"][0] === "z"' v-text='"性別不明"' />
          <span v-else-if='sid === "agez" && ans["agez"][0] === "1"' v-text='"年齢不明"' />
          <span v-else-if='sid === "adult"
                        || sid === "child"
                        || sid === "old"' v-text='local_master[sid]' />
        </div>
      </div>
    </template>

    <template v-if="some_question_answered_p">
      <h3 class="sc-cond-h3">問診の回答</h3>
      <div v-for="(sid, i) in ans_yes" :key="i">
        <div>{{local_master[sid].name}}について該当するものはありますか？</div>
        <div class="tags" style="margin-top: 4px;">
          <div class="tag" v-for="(_ans, j) in local_master[sid]['question']" :key="j" v-text='_ans["text"]' />
        </div>
      </div>
    </template>

    <a style="margin-top: 2rem;" class="button is-blue is-fullwidth" href="/symptoms/" v-text='"最初からやりなおす"' />
  </div>
</template>

<style scoped>
  .sc-cond-h3 {
    font-weight: bold;
    margin-bottom: 8px;
  }
</style>
