<script>
  /*
     主にsp用。
     vm.$store.state.modal_opts を監視して、いい感じのモーダルを表示する。
     widnowにつき一つ設定しとく。
   */
  import lib from './lib.vue';
  import modalReaction from './modal_reaction.vue';
  import searchBox from './search_box.vue';
  import naviBox from './navi_box.vue';
  // import spMenuIcon from './sp_menu_icon.vue';
  export default {
    mixins: [lib],
    components: {
      searchBox,
      naviBox,
      // spMenuIcon,
      modalReaction,
    },
    props: {
      search_box_arg: null,
    },
    watch: {
      '$store.state.modal_opts' (_to, _fr) {
        var vm = this;
        vm.logd("hello modal:", _to);

        var fix_body_tgts = ['search_box', 'sp_menu'];
        var fix_body_p = false;
        if ((_to && fix_body_tgts.indexOf(_to.type) !== -1) ||
            (_fr &&  fix_body_tgts.indexOf(_fr.type) !== -1)) {
          fix_body_p = true;
        }

        if (fix_body_p) {
          if (_to) {
            // modal中はfixedにする。
            document.body.style['position'] = 'fixed';
            vm.$store.state.page_y_offset_frozen = vm.$store.state.page_y_offset;
            window.scrollTo(0, 0);
          } else {
            document.body.style['position'] = '';
            window.scrollTo(0, vm.$store.state.page_y_offset_frozen);
            vm.$store.state.page_y_offset = vm.$store.state.page_y_offset_frozen;
            vm.$store.state.page_y_offset_frozen = 0;
          }
        }
      },
    },
    computed: {
      opts: {
        get () {
          return this.$store.state.modal_opts;
        },
        set (x) {
          this.$set(this.$store.state, 'modal_opts', x);
        },
      },
      navi () {
        return this.$store.state.navi;
      },
    },
    data () {
      return {
        medley_navi: null,
        map_search_path: '',
      };
    },
    mounted () {
      var vm = this;
      if (vm.presentp(window.medley_navi) && window.medley_navi.length > 1) {
        // window.medley_naviにerbで情報をセットしてる。
        // とりあえず、app/views/users/shared/_module.html.erb でscriptタグを吐き出すことで実現してる。
        // TODO: rubyとjsとのやり取りをapiを使わずやる方法を整理する必要ある。
        vm.medley_navi = window.medley_navi.map(x=>{
          x['activep'] = x['action_name'] === window.medley_navi_active_action_name;
          return x;
        });
      }
      // vm.medley_navi = window.medley_navi;
      if(this.search_box_arg && this.search_box_arg[30] && this.search_box_arg[30].map_search_path) {
        this.map_search_path = this.search_box_arg[30].map_search_path;
      } else {
        this.map_search_path = '/institutions/map';
      }
    },
  };
</script>

<template>
  <div>
    <!-- ------------------------------ -->
    <div v-if='opts && opts.type == "search_box"' class='bulma'>
      <div class='modal is-active'>
        <div class="modal-background" @click.prevent='modal(null);' />
        <div class='modal-close-m' style='color: white;' @click.prevent='modal(null);'>
          <span class='icon xis-medium'><i class='fa fa-lg fa-close' /></span>
        </div>
        <div class='modal-card modal-card-m'>
          <!-- div class="modal-card-head modal-card-head-m">
               検索
               </div -->
          <div class='modal-card-body' style='padding: 20px; border-radius: 6px;'>
            <!-- search-box :p_disp_mode='1' :input_selected_p='true' / -->
            <search-box :p_disp_mode='1' :arg=this.search_box_arg :p_modal='true' />
          </div>

          <!-- footer class='modal-card-foot' style='padding: 4px;' -->
        </div>
      </div>
    </div>
    <!-- ------------------------------ -->
    <div v-if='opts && opts.type == "navi"' class='bulma'>
      <div class='modal is-active'>
        <div class="modal-background" @click.prevent='modal(null);' />
        <div class='modal-close-m' style='color: white;' @click.prevent='modal(null);'>
          <span class='icon xis-medium'><i class='fa fa-lg fa-close' /></span>
        </div>
        <div style='width: calc(100vw - 16px); '>
          <navi-box />
        </div>
      </div>
    </div>

    <!-- ------------------------------ institution's tab's modal -->
    <div v-if='opts && opts.type == "ar_linktag"'>
      <div class='modal is-active'>
        <div class="modal-background" @click.prevent='modal(null);' />
        <div class='modal-close-m' style='color: white;' @click.prevent='modal(null);'>
          <span class='icon xis-medium'><i class='fa fa-lg fa-close' /></span>
        </div>
        <div class='modal-content'>

          <div class="card" id='modalcontainer'>

            <div class="card-header">
              <div class="card-header-title">
                {{opts.title || 'title'}}
              </div>
              <a href="#" class="card-header-icon" @click='modal(null)'>
                <span class="icon">
                  <i class="fa fa-close" aria-hidden="true"></i>
                </span>
              </a>
            </div>

            <div class="card-content">
              <div class='tags'>
                <a class='tag is-medium' v-for='(info, i) in opts.ar_info' :href='info.uri' :key='i'>
                  {{info.label}}
                </a>
              </div>
            </div>
          </div>


        </div>
      </div>
    </div>

    <!-- ------------------------------ general dialog like js's dialog. -->
    <div v-if='opts && opts.type == "dialog"'>
      <div class='modal is-active'>
        <div class="modal-background" @click.prevent='modal(null);' />
        <div class='modal-close-m' style='color: white;' @click.prevent='modal(null);'>
          <span class='icon xis-medium'><i class='fa fa-lg fa-close' /></span>
        </div>
        <div class='modal-content'>

          <div class="card" id='modalcontainer'>
            <div class="card-header">
              <div class="card-header-title">
                {{opts.title || 'title'}}
              </div>
              <a href="#" class="card-header-icon" @click='modal(null)'>
                <span class="icon">
                  <i class="fa fa-close" aria-hidden="true"></i>
                </span>
              </a>
            </div>
            <div class="card-content">
              <div style='padding-top: 1rem; padding-bottom: 1rem;'>
                <div v-html='opts.body' />
              </div>
              <div class="button is-fullwidth" @click="modal(null)">
                閉じる
              </div>
            </div>
          </div>


        </div>
      </div>
    </div>

    <!-- ------------------------------ reaction refact -->
    <div v-if='opts && opts.type == "reaction"'>
      <div class='modal is-active'>
        <div class="modal-background" @click.prevent='modal(null);' />
        <div class='modal-close-m' style='color: white;' @click.prevent='modal(null);'>
          <span class='icon xis-medium'><i class='fa fa-lg fa-close' /></span>
        </div>
        <div class='modal-content'>
          <modal-reaction />
        </div>
      </div>
    </div>

    <div v-if='opts && opts.type == "lightbox"'>
      <div class='modal is-active'>
        <div class="modal-background" @click.prevent='modal(null);' />
        <div class='modal-close-m' style='color: white;' @click.prevent='modal(null);'>
          <span class='icon xis-medium'><i class='fa fa-lg fa-close' /></span>
        </div>
        <div class='modal-content'>
          <img :src='opts.src' style='background-color: white;' />
        </div>
      </div>
    </div>

    <!-- ------------------------------ sp menu trial -->
    <div v-show='opts && opts.type == "sp_menu"' class='bulma'>
      <div class='modal is-active'>
        <div class="modal-background" @click.prevent='modal(null);' />
        <div class='modal-close-m' style='color: white;' @click.prevent='modal(null);'>
          <span class='icon xis-medium'><i class='fa fa-lg fa-close' /></span>
        </div>
        <!-- TODO: extract this part to modal_sp_menu or somethign. -->
        <div style='position: fixed; right:0; top: 0px; width: 260px; height: 100%; overflow: scroll; -webkit-overflow-scrolling: touch;'>
          <transition name="slide-fade">
            <div style='width: 100%; background-color: white; min-height: 100%;' v-show='opts && opts.type == "sp_menu"'>
              <div>
                <h2 class="o-sidebar__title is-pink">
                  <a href='/symptoms/' class='fw-b icon-symptoms'>
                    <span class="mr5">症状から調べる</span>
                    <span class="tag is-light">Beta</span>
                  </a>
                </h2>
                <h2 class="o-sidebar__title is-pink">
                  <a href='/diseases/' class="fw-b icon-diseases">
                    病気を調べる
                  </a>
                </h2>
                <h2 class="o-sidebar__title is-pink">
                  <a href='/medicines/' class='fw-b icon-medicine'>
                    薬を調べる
                  </a>
                </h2>
                <ul class="clearfix m0 bb is-black">
                  <li class="o-sidebar__item fc-bg600 br bb">
                    <a href='/medicines/prescription/' class='icon-prescription'>処方薬</a>
                  </li>
                  <li class="o-sidebar__item fc-bg600 bb">
                    <a href='/medicines/otc/' class='icon-otc'>市販薬</a>
                  </li>
                  <li class="o-sidebar__item fc-bg600 br xbb">
                    <a href='/medicines/article/' class='icon-medicine_comment'>薬の解説</a>
                  </li>
                </ul>
                <h2 class="o-sidebar__title is-pink">
                  <a href='/institutions/' class='fw-b icon-institutions'>病院を探す</a>
                </h2>
                <ul class="clearfix m0 bb is-black">
                  <li class="o-sidebar__item fc-bg600 br bb">
                    <a href='/institutions/'><i class='fa fa-search'></i> 条件から</a>
                  </li>
                  <li class="o-sidebar__item fc-bg600 bb">
                    <a v-bind:href=this.map_search_path><i class='fa fa-map-marker'></i> 現在地から</a>
                  </li>
                  <li class="o-sidebar__item fc-bg600 br bb">
                    <a href='/institutions/specialist/'>専門医から</a>
                  </li>
                  <li class="o-sidebar__item fc-bg600 bb">
                    <a href='/institutions/specialty_outpatient/'>専門外来から</a>
                  </li>
                  <li class="o-sidebar__item fc-bg600 br">
                    <a href='/institutions/preventive_inoculation/'>予防接種から</a>
                  </li>
                </ul>
                <h2 class="o-sidebar__title is-pink">
                  <a href='/news/column' class='fw-b icon-news'>ニュースを読む</a>
                </h2>
                <div class="bg-gray pt20 pb50">
                  <div class="bg-white p20 ta-c mb20">
                    <a href='/pages/concept/'>MEDLEYについて</a>
                  </div>

                  <template v-if='$store.state.ruby_env.medley_user_status > 0'>
                    <div class='columns is-mobile is-0'>
                      <div class="column has-text-centered">
                        <a class='button is-primary has-text-white' href='/o/x/'>マイページ</a>
                      </div>
                      <div class="column has-text-centered">
                        <a class='button'
                           href='/me/logout/'>ログアウト
                        </a>
                      </div>
                    </div>
                  </template>
                  <template v-else>
                    <div class='columns is-mobile is-0'>
                      <div class="column has-text-centered">
                        <a class='button is-primary has-text-white' href='/me/concept/'>医師登録</a>
                      </div>
                      <div class="column has-text-centered">
                        <a class='button' href='/me/login/'>ログイン</a>
                      </div>
                    </div>
                  </template>

                  <!--

                  <template v-if='$store.state.ruby_env.login_stat == "guest"'>
                    <div class="bg-white p20 ta-c mb20">
                      <a href='/me/login/'>医師登録 / 医師ログイン</a>
                    </div>
                  </template>
                  <template v-else-if='$store.state.ruby_env.login_stat == "confirming"'>
                    <div class="bg-white p20 ta-c mb20">
                      <a href='/me/logout/'>ログアウト</a>
                    </div>
                  </template>
                  <template v-else-if='$store.state.ruby_env.login_stat == "doctor" || $store.state.ruby_env.login_stat == "admin"'>
                    <ul class="clearfix m0 bb bt">
                      <li class="o-sidebar__item fc-bg600 bg-white">
                        <a href='/me/profession/'>医師情報修正</a>
                      </li>
                      <li class="o-sidebar__item fc-bg600 bg-white">
                        <a href='/me/logout/'>ログアウト</a>
                      </li>
                    </ul>
                  </template>

                  -->

                  <!--
                       <% case @login_stat %>
                       <% when :guest %>
                       <% when :doctor %>
                       <ul class="clearfix m0 bb bt">
                       <li class="o-sidebar__item fc-bg600 bg-white"><%= link_to "医師情報修正", me_profession_path, class: "br ta-c" %></li>
                       <li class="o-sidebar__item fc-bg600 bg-white"><%= link_to "ログアウト", me_logout_path, class: "ta-c" %></li>
                       </ul>
                       <% else %>
                       <div class="bg-white p20 ta-c mb20"><%= link_to "ログアウト", me_logout_path %></div>
                       <% end %>
                  -->
                </div>
              </div>

            </div>
          </transition>

        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
  .tabs.tabs-m:not(:last-child) {
    margin-bottom: 4px;
  }
  .modal-close-m {
    position: fixed;
    right: 4px;
    top: 4px;
    padding: 0;
  }
  .modal-card.modal-card-m {
    max-height: calc(100vh - 192px);
    width: 95%;
    padding: 0;
    margin: 0;
    border-radius: 6px;
  }
  .modal-card-head.modal-card-head-m {
    padding: 8px 16px; font-size: 0.95rem; font-weight: bold;
  }
  .tabs.tabs-m > ul > li.is-active > a {
    padding: 4px 2px;
  }
  .tabs.tabs-m.is-info > ul > li.is-active > a {
    background-color: hsl(217, 71%,  53%);
    border-color: hsl(217, 71%,  53%);
  }
  .tabs.tabs-m > ul > li.is-active > a {
    /* background-color: pink; */
    font-weight: bold;
  }

  .slide-fade-enter-active {
    transition: all .3s ease;
  }
  .slide-fade-leave-active {
    transition: all .3s ease;
  }
  .slide-fade-enter, .slide-fade-leave-to {
    transform: translateX(60px);
    opacity: 0;
  }
</style>
