<script>
  import VueTypeahead from 'vue-typeahead';
  import lib from './lib.vue';
  export default {
    extends: VueTypeahead,
    mixins: [lib],
    props: [
      'not_standalone_p', // standalone is define drop down position with position: relative style.
      'value',
      'className',
      'styleName',
      // 'mode', // 0: location only, 1: station only, else: both(old behavior)

      'p_src',
      'p_placeholder',

      'value_disp_only', // UGLY hack.
    ],
    data () {
      return {
        src: '/api/u/institution_search/slocation',
        placeholder: '地名で検索する',
        limit: 10,
        minChars: 2,
      };
    },
    created () {
      var vm = this;

      if (vm.p_src) {
        vm.src = vm.p_src;
      }
      if (vm.p_placeholder) {
        vm.placeholder = vm.p_placeholder;
      }

      // 読み込み時に検索中のキーワードを反映
      vm.query = vm.value;

      // 親コンポーネントによるキーワード変更を検知
      vm.$watch('value', function () {
        vm.query = vm.value;
      });
    },
    methods: {
      iconClass: function (type) {
        switch (type) {
          case 'station':
            return 'icon-access fc-bg500 mr5';
          default:
            return 'icon-address fc-bg500 mr5';
        }
      },
      hit: function (e) {
        if (this.current !== -1) {
          this.onHit(this.items[this.current]);
        } else {
          // 明示的にsubmitを防ぐ
          e.preventDefault();
        }
      },
      onHit: function (item) {
        this.query = item.name || '';
        this.$emit('minput', item);
      },
      // queryを残したいのでoverrideする
      reset: function () {
        this.items = [];
        // this.query = ''
        this.loading = false;
      },
      prepareResponseData: function (data) {
        return data['data'];
      }
    }
  };
</script>

<template>
  <div :style='{position: not_standalone_p ? "static" : "relative"}'>
    <input type="text"
           class="input"
           :placeholder="presentp(value_disp_only) ? value_disp_only : placeholder"
           autocomplete="off"
           v-model="query"
           v-on:keydown.down="down"
           v-on:keydown.up="up"
           v-on:keydown.enter="hit"
           v-on:keydown.esc="reset"
           v-on:blur="reset"
           v-on:input="update"/>
    <ul v-show="hasItems">
      <li v-for="(item, $item) in items"
          :key="$item"          
          :class="activeClass($item)" @mousedown="hit" @mousemove="setActive($item)">
        <span v-bind:class="iconClass(item.type)" v-text="item.name"></span>
      </li>
    </ul>
    <!-- div>{{value_disp_only}}</div -->
  </div>
</template>

<style scoped>
  .Typeahead {
    /* position: relative; */
  }
  .Typeahead__input {
    width: 100%;
    font-size: 13px;
    color: #2c3e50;
    line-height: 1.332857143;
    box-shadow: inset 0 1px 4px rgba(0,0,0,.4);
    -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    font-weight: 300;
    padding: 12px 26px;
    border: none;
    border-radius: 22px;
    letter-spacing: 1px;
    box-sizing: border-box;
  }
  .Typeahead__input:focus {
    border-color: #4fc08d;
    outline: 0;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px #4fc08d;
  }
  .fa-times {
    cursor: pointer;
  }
  i {
    float: right;
    position: relative;
    top: 30px;
    right: 29px;
    opacity: 0.4;
  }
  ul {
    position: absolute;
    padding: 0;
    top: 50%;
    padding: 0;
    margin-top: 28px;
    min-width: 100%;
    background-color: #fff;
    list-style: none;
    border-radius: 4px;
    box-shadow: 0 0 10px rgba(0,0,0, 0.25);
    z-index: 10100;
  }
  li {
    font-size: 13px;
    line-height: 22px;
    padding: 10px 16px;
    border-bottom: 1px solid #ccc;
    cursor: pointer;
    margin: 0;
  }
  li:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  li:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-bottom: 0;
  }
  span {
    display: block;
    color: #2c3e50;
  }
  .active {
    background-color: #EFF2F7;
  }
  .active span {
    color: #2c3e50;
  }
  .name {
    font-weight: 700;
    font-size: 18px;
  }
  .screen-name {
    font-style: italic;
  }
</style>
