<script>
  import scCommon from './sc_common.vue';
  export default {
    mixins: [scCommon]
  };
</script>

<template>
  <div>
    <template v-if="presentp(important_diseases)">
      <div class="tags">
        <div class="tag" v-for="(disease, i) in important_diseases" :key="i">
          <a :href='`/diseases/${disease.id}`' class='decorative-link' target='_blank'>
            {{disease.name}}
          </a>
        </div>
      </div>
    </template>
  </div>
</template>

<style scoped>
  .sc-important-h3 {
    font-weight: bold;
    margin-bottom: 8px;
  }
</style>
