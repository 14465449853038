<script>
  export default {
    props: {
      p_openp: { default: false },
      label: { default: '詳細' },
    },
    data () {
      return {
        openp: false,
      };
    },
    mounted () {
      var vm = this;
      vm.openp = vm.p_openp;
    },
    methods: {
      poke () {
        var vm = this;
        vm.openp = !vm.openp;
      },
    },
  };
</script>

<template>
  <div>
    <div class='card is-blue'>
      <div class='is-fullwidth'
           @click='poke'
           style='display: block; background-color: #f5f7fa; padding: 4px; margin: 0 0px; text-align: center; '
      >
        <i class='fa fa-minus' v-if='openp' />
        <i class='fa fa-plus'  v-else />
        &nbsp;
        {{label}}
      </div>
    </div>
    <div class='card' v-if='openp'>
      <div class='card-content'>
        <slot name='main' />
      </div>
    </div>
  </div>
</template>
