<script>
export default {
  props: [
    'title'
  ],
  data: function () {
    return {
      isActive: false
    };
  }
};
</script>

<template>
<li class="border p0 mb10" v-bind:class="{active: isActive}">
  <a class="accordion-title" v-on:click.prevent="isActive = !isActive" href="#" v-bind:class="{active: isActive}">
    <slot name="title"></slot>
  </a>
  <div class="accordion-body mt20" v-show="isActive" v-bind:class="{active: isActive}">
    <slot name="body"></slot>
  </div>
</li>
</template>

<style scoped>
.accordion-title {
    color: #3C4858;
    display: block;
    padding: 20px;
    position: relative;
    margin: 0;
}
.accordion-title:before {
    position: absolute;
    top: 35%;
    right: 15px;
    transition: all 300ms 0s ease;
}
.accordion-title.active {
    margin-bottom: 20px;
    border-bottom: 0;
    padding-bottom: 0;
}
.accordion-title.active:before {
    transition: all 500ms ease;
}
.accordion-body {
    padding: 0 20px;
    display: none;
}
.accordion-body.active {
    display: block;
}
</style>
