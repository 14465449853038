<script>
  export default {
    props: {
      model: null,
      pref_to_uri_mode: {
        default: 0
      },
      // windows ie can't handle closure as prop...
      ///////////
      //default: ()=>{
      //    return (x)=>{
      //      return encodeURI(`/institutions/pref_${x}/`);
      //    };
      //  },
      //},
    },
    data () {
      return {
        area: null,
      };
    },
    computed: {
      pref_to_uri () {
        var vm = this;
        if (vm.pref_to_uri_mode === 0) {
          return x=>encodeURI(`/institutions/pref_${x}/`);
        }
        return x=>encodeURI(`/institutions/linepref_${x}/`);
      },
      ar_item () {
        var vm = this;
        var x;
        if (vm.area) {
          // var x = Object.keys(vm.model);
          x = vm.model[vm.area];
          return x;
        }

        x = Object.keys(vm.model);
        return x;
      },
      ar_item_top () {
        var vm = this;
        return Object.keys(vm.model);
      },
    },
    methods: {
      // pref_to_uri (x) {
      //   return encodeURI(`/institutions/pref_${x}/`);
      // },
      poke (x) {
        var vm = this;
        if (vm.area) {
          var link = vm.pref_to_uri(x);
          vm.mset_location(link);
        } else {
          vm.area = x;
        }
      },
    },
  };
</script>
<template>
  <div>
    <transition name="fade">
      <div v-if='area' style='margin-bottom: 8px;'>
        <div class='button is-light is-blue' @click='area = null;'>
          <i class='fa fa-chevron-left' />
          &nbsp;
          戻る
        </div>
      </div>
    </transition>

    <!--
    <div class='columns is-mobile is-multiline is-1'>
      <div
        :key='itemi'
        v-for='(item,itemi) in ar_item'
        class='column is-4'
      >
        <div class='card has-text-flex-centered has-text-weight-bold' style='height: 4rem;'
             @click=' poke(item) '>
          <a v-if='area' :href=' pref_to_uri(item) ' class='xis-black'>
            {{item}}
          </a>
          <span v-else>
            {{item}}
          </span>
        </div>
      </div>
    </div>
    -->

    <div class='columns is-mobile is-multiline is-1'>
      <template v-for='item in ar_item_top' >
        <div class='column is-4'
             :key='item'
             v-show=' !area '
        >
          <div class='card has-text-flex-centered has-text-weight-bold'
               style='height: 4rem;'
               @click=' poke(item) '>
            {{item}}
          </div>
        </div>
        <template v-for='xitem in model[item]' >
          <div class='column is-4'
               :key='xitem'
               v-show=' area == item '
          >
            <div class='card has-text-flex-centered has-text-weight-bold'
                 style='height: 4rem;'
            >
              <a :href=' pref_to_uri(xitem) '>
                {{xitem}}
              </a>
            </div>
          </div>
        </template>

      </template>
    </div>

  </div>
</template>

<style scoped>
  .fade-enter-active, .fade-leave-active {
    transition: opacity .2s;
  }
  .fade-enter, .fade-leave-to {
    opacity: 0;
  }
</style>
