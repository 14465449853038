<script>
  import lib from './lib.vue';
  export default {
    mixins: [lib],
    data () {
      return {
        target: 'not-specified',
        doctorreactionp: false,
        doctorreactioncontent: '',
      };
    },
    props: [
      'p_class',
    ],
    created () {
      const pn = location.pathname;
      if (pn.match(new RegExp('^/diseases/[0-9a-zA-Z]{24}/details'))) {
        this.target = 'featured-article disease';
      } else if (pn.match(new RegExp('^/diseases/topics/[0-9a-zA-Z]{24}/details'))) {
        this.target = 'featured-article disease-topic';
      } else if (pn.match(new RegExp('^/symptoms/[0-9a-zA-Z]+/details'))) {
        this.target = 'featured-article symptom';

      } else if (pn.match(new RegExp('^/symptoms/[0-9a-zA-Z]+/basic'))) {
        this.target = 'symptom';
      } else if (pn.match(new RegExp('^/diseases/[0-9a-zA-Z]{24}'))) {
        this.target = 'disease';
      } else if (pn.match(new RegExp('^/medicines/otc/[0-9a-zA-Z]+'))) {
        this.target = 'medicine-otc';
      } else if (pn.match(new RegExp('^/medicines/prescription/[0-9a-zA-Z]+'))) {
        this.target = 'medicine-prescription';
      } else if (pn.match(new RegExp('^/medicines/article/[0-9a-zA-Z]+'))) {
        this.target = 'medicine-article';
      }
    },
    methods: {
      doctorreaction () {
        var vm = this;
        var info = {
          type: 'mes',
          target: vm.target,
          url: window.location.href,
          text: vm.doctorreactioncontent,
          score: 3,
        };
        vm.mhttp('post', '/api/u/reactions',
                 info,
                 res=>{
                   vm.$dialog.alert({
                     title: 'フィードバックを受け付けました',
                     message: 'フィードバックありがとうございます。参考にさせていただきます。',
                   });
                 });

        vm.doctorreactionp = false;
      },
      vote (score) {
        const info = {
          type: 'vote',
          target: this.target,
          url: window.location.href,
          score: score};
        this.mhttp('post',
                   '/api/u/reactions',
                   info,
                   res => {
                     info.type = 'reaction';
                     this.modal(info);
                   },
                   e => {
                     alert('投稿に失敗しました');
                   });
      },
    }
  };
</script>

<template>
  <div class="o-gray-box" :class='p_class'>

    <template v-if=' [8, 16].indexOf( $store.state.ruby_env.medley_user_status ) != -1 '>
      <p class="o-gray-box__center_title">
        この記事についての医師としてのフィードバックをお待ちしています
      </p>
      <div class='has-text-centered' style='margin-top: 24px;'>
        <button class='button is-primary'
                style=''
                @click='doctorreactionp = true'
        >
          フィードバック
        </button>
      </div>
      <b-modal
        v-if='doctorreactionp'
        :active='true'
      >
        <div class='card'>
          <div class='card-header'>
            <div class='card-header-title'>
              フィードバックをご入力ください
            </div>
          </div>
          <div class='card-content'>
            <p>
              医師としてのご意見をお待ちしております。
            </p>
            <b-field>
              <b-input type='textarea'
                       v-model='doctorreactioncontent'
                       rows='20'
              />
            </b-field>
            <b-field>
              <button
                class='button is-primary'
                @click='doctorreaction'
                v-text='"送信する"'
                :disabled=' !(doctorreactioncontent && doctorreactioncontent.length > 0) '
              />
              &nbsp;
              <button
                class='button'
                @click='doctorreactionp = false;'
                v-text='"閉じる"'
              />
            </b-field>
          </div>
        </div>
      </b-modal>
    </template>


    <template v-else>
      <p class="o-gray-box__center_title">
        この記事は役に立ちましたか？
      </p>
      <div class="clearfix w260 m-c mt20 mb20">
        <button v-on:click="vote(1)" class="border w120 bg-white ta-c pt10 pb10 cr-p m0 fl-l">
          <p class="icon-satisfied m0"></p>
          <p class="mt5 mb0 fs-m-s">役に立った</p>
        </button>
        <button v-on:click="vote(2)" class="border w120 bg-white ta-c pt10 pb10 cr-p m0 fl-r">
          <p class="icon-dissatisfaction m0"></p>
          <p class="mt5 mb0 fs-m-s">不十分だった</p>
        </button>
      </div>

      <p class="ta-c m0 fs-s-s">
        MEDLEY運営チームに、記事に対しての<br>感想コメントを送ることができます
      </p>
    </template>
  </div>
</template>
