<template>
  <span>
    <span class="vue-tooltip" @click.stop="open" @mouseover.stop='open' @xmouseleave='close'>
      <slot></slot>
    </span>
  </span>
</template>

<script>
  // ref: http://it-challe.com/responsive-tooltip/

  import lib from './lib.vue';
  export default {
    mixins: [lib],
    props: [
      'content'
    ],
    data: function () {
      return {
        windowWidth: 0,
        windowHeight: 0,
        originalWidth: 0,
        top: 0,
        left: 0
      };
    },
    created: function () {
      this.windowWidth = window.innerWidth;
      this.windowHeight = window.innerHeight;
    },
    methods: {
      open: function (e) {
        var vm = this;
        vm.position(e);
        vm.tooltip({
          content: this.content,
          top: this.top,
          left: this.left,
        });
      },
      close: function (e) {
        var vm = this;
        vm.tooltip(null);
      },

      position: function (e) {
        var scrollX = document.body.scrollLeft;
        var tooltipRect = e.target.getBoundingClientRect(); // tooltipリンクの矩形取得
        var boxWidth = 200;
        var boxLeft = tooltipRect.left + window.pageXOffset;
        var boxTop = tooltipRect.top + window.pageYOffset + 15; // tooltipの下にtooltipBoxを表示

        /* tooltipBoxの右端がウィンドウからはみ出る場合 */
        var boxRight = boxLeft + boxWidth;
        var windowRight = this.windowWidth + scrollX;
        if (boxRight > windowRight) {
          // はみ出る分だけ左にずらす
          boxLeft = boxLeft - (boxRight - windowRight) - 40;
        }

        /* ツールチップの位置を設定 */
        this.top = boxTop;
        this.left = boxLeft;
      }
    }
  };
</script>
<style scoped>
  .vue-tooltip {
    background-image: linear-gradient(180deg, transparent 96%, #c0ccda  0);
  }
</style>
