<script>
  import lib from './lib.vue';
  export default {
    mixins: [lib],
    props: {
    },
    data () {
      return {
        info: {
          email: '', 
          name: '',
        },
        message: {
          email: null,
          name: null
        },
      };
    },
    created () {
      var vm = this;
      window.vm = vm;
      vm.validate();
    },
    watch: {
      info: {
        handler () {
          var vm = this;
          vm.validate();
        },
        deep: true,
      },
    },
    methods: {
      validate () {
        var vm = this;
        vm.message.name = null;
        vm.message.email = null;
        if (!vm.presentp(vm.info.name)) {
          vm.message.name = '必須です';
        }
        console.log('detect', vm.info.email);
        if (!vm.presentp(vm.info.email)) {
          vm.message.email = '必須です';
        } else if (!vm.valid_emailp(vm.info.email)) {
          vm.message.email = '形式が不正です';
        }
      },
      execute () {
        var vm = this;
        vm.mhttp('post', '/me/register', vm.info,
                 res=>{
                   var error = res.data.error;
                   if (error) {
                     var error_message = res.data.error_message;
                     vm.$dialog.alert({
                       message: error_message,
                     });
                     return;
                   }
                   vm.info.name = null;
                   vm.info.email = null;
                   vm.message.name = null;
                   vm.message.email = null;
                   vm.$dialog.alert({
                     message: 'メールアドレス確認のためのメールを送付いたしました。',
                     onConfirm () {
                       vm.mset_location('/');
                     },
                   });
                 });
      },
    },
  };
</script>

<template>
  <div class='columns is-4'>
    <div class='column is-6'>
      <div class='field'>
        <a href='/me/facebook_auth_req' class='button is-large is-fullwidth is-info' style='background-color: #3b5998;'>
          <i class='fab fa-facebook mr10'></i>Facebookで登録
        </a>
      </div>

      <hr style='margin-bottom: 24px; margin-top: 24px;' />

      <p class="has-text-centered">
        ログインは
        <a href='/me/login/'>
          こちら
        </a>
        から
      </p>

    </div>
    <div class='column is-6'>
      <!-- form method='POST' action='/me/register' -->
      <input type="hidden" name="authenticity_token" :value="csrf_token()">

      <b-field
        label='ご氏名(ご本名を入力してください)'
        :message='message.name'
        :type='{"is-danger": message.name}'
      >
        <b-input v-model='info.name' />
      </b-field>

      <b-field
        label='メールアドレス'
        :message='message.email'
        :type='{"is-danger": message.email}'
      >
        <b-input v-model='info.email' />
      </b-field>

      <p class="has-text-centered">
        登録することで<a href='/pages/terms'>MEDLEY利用規約</a>に同意したものとします。
      </p>

      <div class='field'>
        <button
          class='button is-large is-fullwidth is-primary'
          @click='execute'
          :disabled=' message.name || message.email '
        >
          メールアドレスで登録
        </button>
      </div>
      <!-- /form -->
    </div>
  </div>
</template>
