<script>
  import lib from './lib.vue';
  export default {
    mixins: [lib],
    components: {
    },
    props: {
      delayp: {
        default: false,
      },
      p_style: {
        default: '',
      },
      p_style_menu: {
        default: '',
      },
    },
    data () {
      return {
        activep: false,
      };
    },
    mounted () {
      var vm = this;
      // vm.$el.classList.replace('is-loading', 'is-loaded');
      vm.load();
    },
    methods: {
      activate (x) {
        var vm = this;
        vm.activep = x;
      },
      poke () {
        var vm = this;
        vm.activep = !vm.activep;
      },
      load () {
        var vm = this;
        // to loaded.
        // vm.$el.classList.remove('is-loading');
        // vm.$el.classList.add('is-loaded');
        this.logd("slots:", vm.$slots);
        vm.$slots['trigger'][0].elm.classList.remove('is-loading');
        vm.$slots['menu'][0].elm.classList.remove('is-loading');
      },
    },
  };
</script>

<template>
  <!--
       :class="{'is-active': activep}"
     -->
  <div class='dropdown is-active'

       @mouseover="activate(true);"
       @mouseleave="activate(false);"
       :style='p_style'
  >
    <div class='dropdown-trigger' style='width: 100%; height: 100%; display: flex; justify-content: center; align-items: center;'
         @click='poke();'
    >
      <slot name='trigger' />
      <!--
           <div class='button' style='width: 100%; height: 100%;'>
           trigger
           </div>
         -->
    </div>
    <transition :name='delayp ? "" : "rapid" '>
      <div class="dropdown-menu" :style='p_style_menu' v-show='activep'>
        <slot name='menu' />
      </div>
    </transition>
    <!-- div style='position: fixed; left: 0; top: 0; width: 100vw; backgrou' / -->
  </div>
</template>

<style scoped>
  /*
  .v-enter-active, .v-leave-active {
    transition: opacity 1s;
  }
  .v-enter, .v-leave-to {
    opacity: 0;
  }
  */
  .rapid-enter-active {
    color: transparent;
    transition: all 0.0s 0.0s;
  }
  .v-enter-active {
    color: transparent;
    transition: all 0.0s 0.4s;
  }
  .v-enter {
    opacity: 0;
  }
  /*
  .v-leave-active {
    transition: all 0.0s;
  }

  .v-leave-to {
    opacity: 0;
    transform: translateX(50px);
  }
  */
</style>
