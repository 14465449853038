<script>
  import lib from './lib.vue';
  export default {
    mixins: [lib],
    computed: {
      ref_tooltip () {
        var vm = this;
        return vm.$store.state.tooltip;
      }
    },
  };
</script>

<template>
  <span v-if='ref_tooltip'
        class='vue-tooltip-box'
        :style="{top: `${ref_tooltip.top}px`, left: `${ref_tooltip.left}px`}"
        @click.prevent="tooltip(null)"
  >
    <span class="vue-tooltip-box__text">
      {{ ref_tooltip.content }}
    </span>
  </span>
</template>

<style scoped>
  .vue-tooltip-box {
    /* position: fixed; */
    position: absolute;

    border-radius: 10px;
    padding: 8px;
    width: 220px;

    white-space: normal;
    z-index: 100;
  }
  .vue-tooltip-box__text {
    font-feature-settings: 'palt';
    font-size: 14px;
    letter-spacing: .03em;
    line-height: 1.57143;
    background: #273444;
    padding: 10px;
    border-radius: 5px;
    color: #ffffff;
    display: block;
    text-align: center;
  }
</style>
