<script>
  import lib from './lib.vue';
  export default {
    mixins: [lib],
    props: {
      'p_search': {},
    },
    computed: {
      qs: {
        set (x) {
          var vm = this;
          vm.$set(vm.$store.state, 'qs', x);
        },
        get () {
          var vm = this;
          return vm.$store.state.qs;
        },
      },
    },
    created () {
      var vm = this;

      vm.qinfo_list = [
        {id: 'q', name: 'キーワード'},
        {id: 'qcompo', name: '成分'},
        {id: 'qcategory0', name: 'カテゴリ0'},
        {id: 'qcategory1', name: 'カテゴリ1'},
        {id: 'qmanu', name: '製薬会社'},
      ];

      vm.qinfo = {};
      vm.qinfo_list.forEach(x=>{
        vm.qinfo[x['id']] = x;
      });

      if (vm.mode_from_uri() === 22) {
        var qh = vm.$qs.parse(location.search);
        Object.keys(vm.qinfo).forEach(x=>{
          vm.$set(vm.qs, x, qh[x] || '');
        });
      }
    },
    methods: {
      category_changed (i) {
        var vm = this;
        // this.logd("category_changed:", i);
        if (i === 0) {
          vm.$store.state.qs.qcategory1 = '';
        }
      },
      clear_qs () {
        var vm = this;
        Object.keys(vm.qinfo).forEach(x=>{
          vm.$set(vm.qs, x, '');
        });
      },
    },
  };
</script>

<template>
  <div class='bulma' style='margin: 0; padding: 0;'>
    <div
        ref='detail'
        style='overflow: hidden; margin: 16px 0 0; padding: 0;'
    >

      <p v-text='qinfo["qmanu"]["name"] + ""' />
      <div class='field has-addons'>
        <div class='control is-expanded'>
          <input class='input'
                 v-model='qs.qmanu'
                 @keypress.enter='p_search' />
        </div>
        <div class='control'>
          <div class='button' v-text='"クリア"' @click='qs.qmanu = ""; search();' />
        </div>
      </div>

      <!-- ------------------------------ -->
      <template v-if='presentp($store.state.search_condition)'>

        <p v-text='"カテゴリ"' />

        <div class='field'>
          <div class='control is-expanded'>
            <div class="select is-fullwidth">
              <select v-model='$store.state.qs.qcategory0' @change='category_changed(0)'>
                <option value=''>--</option>
                <option v-for='x in $store.state.search_condition.otc_category.root' :value='x.id' :key='x.id'>
                  {{x.name}}
                </option>
              </select>
            </div>
          </div>
        </div>
        <template v-if='presentp($store.state.qs.qcategory0) && $store.state.qs.qcategory0 != ""'>
          <div class='field'>
            <div class='control is-expanded'>
              <div class="select is-fullwidth">
                <select v-model='$store.state.qs.qcategory1' @change='category_changed(1)'>
                  <option value=''>--</option>
                  <option v-for='x in $store.state.search_condition.otc_category[$store.state.qs.qcategory0]' :value='x.id' :key='x.id'>
                    {{x.name}}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </template>

      </template>

      <div style='height: 8px;' />

      <div class='field'>
        <div class="control has-text-centered">

          <button class='button is-expanded xis-large' @click='clear_qs'>
            全てクリア
          </button>
          &nbsp;&nbsp;
          <button
              class='button is-expanded xis-large is-primary xicon-search'
              @click='p_search({enforcep: true})'>
            <i class='fa fa-search'></i>
            &nbsp;
            検索
          </button>
        </div>
      </div>
      <div style='hight: 8px;' />
    </div>
  </div>
</template>

<style scoped>
  p {
    font-weight: bold;
    margin: 0;
  }
  .card {
    display: block;
  }
  .card.closedp {
    display: none;
  }
  .card-content {
    padding: 12px;
  }
</style>
