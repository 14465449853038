<script>
  // 各カテゴリ(処方薬、ニュースなど)のページ毎に偏在させる検索フォームの最小要素
  import lib from './lib.vue';
  export default {
    mixins: [lib],
    props: {
      result_total: {default: 0},
      result_from: {default: 0},
      result_to: {default: 0},
    },
    data () {
      return {
        cond_s: [],
        qh: {},
        mode: 0,
        need_to_get_label_p: false,
        need_to_get_label_info: {},
      };
    },
    created () {
      var vm = this;

      vm.mode = parseInt(vm.$store.state.search_mode);
      var mode_cond = {
        k: 'mode',
        v: vm.mode,
        // label: `対象:${vm.mode_to_searchname(vm.mode)}`,
        label: `${vm.mode_to_searchname(vm.mode)}`,
      };
      // this.logd("MODE:", mode);
      vm.cond_s.push(mode_cond);
      var qh = vm.$store.state.qh;
      Object.keys(qh).forEach(k=>{
        var kv = {
          k: k,
          v: qh[k]
        };
        var cond = vm.kv_to_cond(kv);
        if (kv.k === 'page') {
          // filter not-condtion param.
          return;
        }
        if (!cond.skipp && vm.presentp(cond.label)) {
          vm.cond_s.push(cond);

          if (cond.need_to_get_label_p) {
            vm.need_to_get_label_p = true;
            vm.need_to_get_label_info[cond.k] = cond.v;
          }
        }
      });

      if (vm.need_to_get_label_p) {
        this.logd("need_to_get_label!");
        vm.mhttp('get', '/api/u/search_condition_label', {
          params: {
            mode: vm.mode,
            info: JSON.stringify(vm.need_to_get_label_info)}},
                 res=>{
                   this.logd("res.data:", res.data);
                   vm.need_to_get_label_info = res.data.info;
                   vm.need_to_get_label_p = false;
                 });
      }
    },
    methods: {
      qh_existp (k) {
        var vm = this;
        var v = vm.$store.state.qh[k];
        this.logd("K:", k, ",v:", v);
        return v;
      },
      kv_to_cond (kv) {
        var vm = this;
        var cond = {...kv};
        cond.skipp = false;
        cond.label = cond.v; // default.
        if (cond.k === 'mode') {
          cond.skipp = true;
          return cond;
        }
        if (cond.k === 'qgenericp') {
          cond.label = vm.qgenericp_to_name(parseInt(cond.v));
          return cond;
        }

        if (vm.mode === 10) {
          if (kv.k === 'qspecialp' && kv.v) {
            cond.label = '特定疾患';
            return cond;
          }
          if (['qfield', 'qbody'].indexOf(kv.k) !== -1) {
            cond.need_to_get_label_p = true;
            return cond;
          }
        }
        if (vm.mode === 21) {
          if (['qcategory0', 'qcategory1', 'qcategory2', 'qcategory3'].indexOf(kv.k) !== -1) {
            const idx = parseInt(kv.k.match(/\d/)[0]);
            const next_idx = idx + 1;
            const qhp = vm.qh_existp(`qcategory${next_idx}`);
            // this.logd("qhp: ", qhp);
            if (qhp === undefined) {
              cond.need_to_get_label_p = true;
              cond.label = cond.v;
              return cond;
            }
            cond.skipp = true;
            return cond;
          }
        }
        if (vm.mode === 22) {
          if (['qcategory0', 'qcategory1'].indexOf(kv.k) !== -1) {
            const idx = parseInt(kv.k.match(/\d/)[0]);
            const next_idx = idx + 1;
            const qhp = vm.qh_existp(`qcategory${next_idx}`);
            if (qhp === undefined) {
              cond.need_to_get_label_p = true;
              cond.label = cond.v;
              return cond;
            }
            cond.skipp = true;
            return cond;
          }
        }
        if (vm.mode === 23) {
          if (['qcategory0', 'qcategory1'].indexOf(kv.k) !== -1) {
            const idx = parseInt(kv.k.match(/\d/)[0]);
            const next_idx = idx + 1;
            const qhp = vm.qh_existp(`qcategory${next_idx}`);
            if (qhp === undefined) {
              cond.need_to_get_label_p = true;
              cond.label = cond.v;
              return cond;
            }
            cond.skipp = true;
            return cond;
          }
        }
        if (vm.mode === 30) {
          // 駅系は、駅以外無視
          if (['size', 'station_pref', 'line'].indexOf(kv.k) !== -1) {
            cond.skipp = true;
            return cond;
          }
          if (kv.k === 'station') {
            cond.need_to_get_label_p = true;
            return cond;
          }
          // 場所系。粒度が細かい条件を優先する。
          if (kv.k === 'pref') {
            if (vm.qh_existp('city')) {
              cond.skipp = true;
              return cond;
            }
          }
          if (kv.k === 'city') {
            if (vm.qh_existp('town')) {
              cond.skipp = true;
              return cond;
            }
          }

          // specialist系
          if (['specialist', 'specialty_outpatient', 'preventive_inoculation'].indexOf(kv.k) !== -1) {
            cond.need_to_get_label_p = true;
            return cond;
          }

          if (kv.k === 'inst_type') {
            // cond.label = kv.v == 'clinic' ? 'クリニック' : '病院';
            cond.label = vm.master[30]['inst_type'][kv.v];
            return cond;
          }

          if (['field', 'disease', 'symptom'].indexOf(kv.k) !== -1) {
            cond.need_to_get_label_p = true;
          }

          // this.logd(`kv k:${kv.k} v:${kv.v}`);
          if (['availabilities[]', 'availabilities'].indexOf(kv.k) !== -1) {
            if (Array.isArray(kv.v)) {
              var labels = kv.v.map(x=>{
                return vm.master[30]['availability'][x]; // value to label.
              });
              cond.label = labels.join(', ');
              return cond;
            } else {
              cond.label = vm.master[30]['availability'][cond.v];
              return cond;
            }
          }
        }
        cond.label = cond.v;
        return cond;
      },
      killme (c) {
        var vm = this;
        vm.logd("killme:", c);
        if (c.k === 'mode') {
          if ({10: 1, 20: 1, 30: 1, 40: 1}[c.v]) {
            const newparamstr = vm.hash_to_paramstr(vm.$store.state.qh);
            window.location = '/search?' + newparamstr;
            return;
          }
        }
        delete vm.$store.state.qh[c.k];
        delete vm.$store.state.qh['page'];
        const newparamstr = vm.hash_to_paramstr(vm.$store.state.qh);
        if (vm.mode === 30) {
          // urlパスのパラメータハック。醜悪。
          window.location.href = `/institutions/?${newparamstr}`;
        } else {
          window.location.search = newparamstr;
        }
      },
    },
    computed: {
    },
  };
</script>

<template>
  <div style='padding: 0.5rem 0.5rem 0.0rem; border-radius: 0px;'
       class='is-bg-gray'
  >
    <!-- <div>検索条件</div> -->
    <!-- {{cond_s}} -->
    <div class='tags'
         v-show=' cond_s && cond_s.length >= 2 '
         style='margin-bottom: 0;'
    >
      <div class='tag has-text-weight-bold is-gray'
           style='background-color: #f5f7f9; border-color: #f5f7f9; margin-right: 0rem;'
           v-text='"条件"'
      />
      <template v-for='(cond, i) in cond_s'>
        <template v-if='cond.k == "mode"'>
          <div
            :key='i'
            v-if='cond.v !== 0'
            class='tag is-white-outlined'
            style='font-weight: bold;'
            @click='killme(cond)'>
            {{cond.label}}
            &nbsp;
            <i class='fa fa-remove is-gray' />
          </div>
        </template>

        <!-- main tag -->
        <template v-else>
          <div
            :key='i'
            class='tag is-white-outlined'
            style='font-weight: bold;'
            @click='killme(cond)'>

            <template v-if='cond.need_to_get_label_p'>
              <template v-if='!need_to_get_label_p'>
                {{need_to_get_label_info[cond.k]}}
              </template>
            </template>
            <template v-else>
              {{cond.label}}
            </template>

            &nbsp;
            <i class='fa fa-remove is-gray' />
          </div>

        </template>
      </template>
    </div>

    <div class='tags'
         style='margin-bottom: 0;'
    >
      <div class='tag has-text-weight-bold is-gray' style='background-color: #f5f7f9; border-color: #f5f7f9;'>
        <span class='is-pink'>{{result_total}}</span> 件中 {{result_from}}-{{result_to}}件
      </div>
    </div>
  </div>
</template>

<style scoped>
</style>
