<script>
import VueTypeahead from "vue-typeahead";
export default {
  extends: VueTypeahead,
  props: [
    "except", // sid's array. already answered sid. TODO: implement.
    "p_class",
    "p_style",
    "placeholder"
  ],
  data() {
    return {
      src: "/api/u/symptom_search/name",
      limit: 10,
      minChars: 1
    };
  },
  created() {},
  methods: {
    hit(e) {
      var vm = this;
      if (vm.current !== -1) {
        var item = vm.items[vm.current];
        vm.$emit("select", item.id);
      } else {
        // 明示的にsubmitを防ぐ
        e.preventDefault();
      }
    },
    prepareResponseData(data) {
      return data["data"];
    }
  }
};
</script>

<template>
  <div style="position: relative;">
    <input
      type="text"
      ref="inputdom"
      class="input"
      :style="p_style"
      :class="p_class"
      :placeholder="placeholder"
      autocomplete="off"
      v-model="query"
      @keydown.down="down"
      @keydown.up="up"
      @keydown.enter="hit"
      @keydown.esc="reset"
      @blur="reset"
      @input="update"
    />
    <div
      style="height: 100%; width: 3em; position: absolute; right: 0px; top: 0px;"
      class="has-text-flex-centered is-gray"
    >
      <div class="fa fa-search" />
    </div>
    <ul v-show="hasItems">
      <li
        v-for="(item, $item) in items"
        :class="activeClass($item)"
        @mousedown="hit"
        @mousemove="setActive($item)"
        :key="$item"
      >
        <span v-text="item.name"></span>
      </li>
    </ul>
  </div>
</template>

<style scoped>
.Typeahead {
  position: relative;
}
.Typeahead__input {
  width: 100%;
  font-size: 14px;
  color: #2c3e50;
  line-height: 1.42857143;
  box-shadow: inset 0 1px 4px rgba(0, 0, 0, 0.4);
  -webkit-transition: border-color ease-in-out 0.15s,
    -webkit-box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  font-weight: 300;
  padding: 12px 26px;
  border: none;
  border-radius: 22px;
  letter-spacing: 1px;
  box-sizing: border-box;
}
.Typeahead__input:focus {
  border-color: #4fc08d;
  outline: 0;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px #4fc08d;
}
.fa-times {
  cursor: pointer;
}
i {
  float: right;
  position: relative;
  top: 30px;
  right: 29px;
  opacity: 0.4;
}
ul {
  position: absolute;
  padding: 0;
  min-width: 100%;
  background-color: #fff;
  list-style: none;
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  z-index: 1000;
}
li {
  padding: 10px 16px;
  border-bottom: 1px solid #ccc;
  cursor: pointer;
  margin: 0;
}
li:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
li:last-child {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom: 0;
}
k span {
  display: block;
  color: #2c3e50;
}
.active {
  background-color: #eff2f7;
}
.name {
  font-weight: 700;
  font-size: 18px;
}
.screen-name {
  font-style: italic;
}
</style>
