<script>
  export default {
    props: ['label', 'term'],
    computed: {
      termclass () {
        var vm = this;
        var x = {};
        x[`fa-${vm.term}`] = true;
        return x;
      },
    },
  };
</script>

<template>
  <div @click='$emit("click")' class='xmain'>
    <!--
    <div class='icon is-medium'>
      <i class='fa fa-2x' style='font-size: 1.6em;'
         :class="termclass" />
    </div>
    -->
    <div class=''>
      <i class='fa'
         :class="termclass"
         style='font-size: 1.3em;'
      />
    </div>
    <div class='xlabel' v-text='label' />
  </div>
</template>

<style scoped>
  .xmain {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 57px;
    height: 55px;
    border-left: 1px solid #e0e6ed;
    background-color: white;
  }
  .xlabel {
    font-weight: bold;
    font-size: 0.6rem;
    margin: -2px 0 0;
    letter-spacing: normal;
  }
</style>
