<script>
export default {
  props: [
    'images' // [{url:'http://aaa.bbb/a.jpg', caption:'bbb'},,,]
  ],
  data: function () {
    return {
      currentImage: {
        url: '', // デフォルトのプレースホルダ画像あれば設定
        caption: ''
      },
      currentIndex: 0
    };
  },
  created: function () {
    if (this.images.length > 0) {
      this.currentImage = this.images[0];
    }
  },
  computed: {
    showImagesBar: function () {
      return this.images.length > 1;
    },
    show: function () {
      return this.images.length > 0;
    }
  },
  methods: {
    changeimage: function (index) {
      this.currentImage = this.images[index];
      this.currentIndex = index;
    }
  }
};
</script>

<template>
<div v-show="show">
  <div class="o-content">
    <div class="o-gray-box mb20 p10 ta-c">
      <figure class="o-medicine-image">
        <img v-bind:src="currentImage.url" />
      </figure>
      <div class="fs-s-s">{{ currentImage.caption }}</div>
    </div>
  </div>
  <div class="mb20 o-medicine-image-subs" v-show="showImagesBar">
    <div class="mb20 o-medicine-image-subs__images">
      <div class="o-medicine-image-subs__images__content">
        <figure class="ta-c p10 o-medicine-image-subs__images__content__image" v-bind:class="{'bg-gray': currentIndex == index}" v-for="(image, index) in images" :key="index">
          <a v-on:click.prevent="changeimage(index)" href="#" class="o-medicine-image-subs__images__content__image__link">
            <img :src="image.url" />
          </a>
        </figure>
      </div>
    </div>
  </div>
</div>
</template>

<style scoped>
img {
    width:auto;
    height:auto;
    max-width:100%;
    max-height:100%;
}
</style>
