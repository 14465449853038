<script>
  import lib from './lib.vue';
  export default {
    mixins: [lib],
    props: {
    },
    data () {
      return {
      };
    },
    created () {
      var vm = this;
    },
    computed: {
    },
    methods: {
    },
  };
</script>

<template>
  <div class=''>

    <div class="dropdown is-right is-active">
      <div class="dropdown-trigger">
        <button class="button" aria-haspopup="true" aria-controls="dropdown-menu6">
          <span>Right aligned</span>
          <span class="icon is-small">
            <i class="fas fa-angle-down" aria-hidden="true"></i>
          </span>
        </button>
      </div>
      <div class="dropdown-menu" id="dropdown-menu6" role="menu">
        <div class="dropdown-content">
          <div class="dropdown-item">
            <p>Add the <code>is-right</code> modifier for a <strong>right-aligned</strong> dropdown.</p>
          </div>
        </div>
      </div>
    </div>


  </div>
</template>
