<script>
  // ugly hack. todo refine it.
  import lib from './lib.vue';
  export default {
    mixins: [lib],
    created () {
      var vm = this;
      window.xdomdom = vm;

      vm.xstick_class = {'top': '120px'};
      if (vm.ie11hell_p) {
        vm.xstick_class['margin-left'] = '-150px';
      }
    },
    data () {
      return {
        xstyle: {},
      };
    },
    watch: {
      '$store.state.page_y_offset' () {
        var vm = this;
        var xtop = 1000;
        if (vm.$el) {
          xtop = vm.$el.getBoundingClientRect().top;
        }
        if (xtop < 120) {
          vm.xstyle = vm.xstick_class;
        } else {
          vm.xstyle = {};
        }
      },
    },
  };
</script>

<template>
  <div class='c-ad is-hidden-mobile'>
    <div :style='xstyle'>
      <slot />
    </div>
  </div>
</template>
