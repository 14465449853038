<script>
  import VueTypeahead from 'vue-typeahead';

  export default {
    extends: VueTypeahead,
    props: [
      'value',
      'className',
      'styleName'
    ],
    data: function () {
      return {
        src: '/api/u/institution_search/diseases_symptoms',
        limit: 10,
        minChars: 1
      };
    },
    created: function () {
      // 読み込み時に検索中のキーワードを反映
      this.query = this.value;

      // 親コンポーネントによるキーワード変更を検知
      this.$watch('value', () => this.query = this.value);
    },
    mounted () {
      // IE11 ではイベントが実行されるタイミングが違うのか、初回描画時に focus されていないのにサジェストが消えないという問題があった
      // vue-typeahead にリクエストをキャンセルする機能がないため、items を読み込んだタイミングを見計らって blur している
      // https://github.com/medley-inc/medley-ghost/issues/1445
      const userAgent = window.navigator.userAgent.toLowerCase();
      if(userAgent.match(/msie|trident/)) {
        const unwatch = this.$watch('items', () => {
          unwatch();
          this.$refs.input.blur();
        });
        this.$refs.input.focus();
      }
    },
    methods: {
      hit: function (e) {
        if (this.current !== -1) {
          this.onHit(this.items[this.current]);
        } else {
          // 明示的にsubmitを防ぐ
          e.preventDefault();
        }
      },
      onHit: function (item) {
        this.query = item.name || '';
        this.$emit('minput', item);
      },
      // queryを残したいのでoverrideする
      reset: function () {
        this.items = [];
        // this.query = ''
        this.loading = false;
      },
      prepareResponseData: function (data) {
        return data['data'];
      },
      mupdate (e) {
        this.update(e);
        this.logd("query:", this.query);
        if (this.query === '') {
          this.$emit('mreset');
        }
      },
    }
  };
</script>

<template>
  <div :style="styleName" style='position: relative;'>
    <input type="text"
           class="input"
           placeholder="症状・病気を検索"
           autocomplete="off"
           ref="input"
           v-model="query"
           @keydown.down="down"
           @keydown.up="up"
           @keydown.enter="hit"
           @keydown.esc="reset"
           @blur="reset"
           @input="mupdate"/>
    <ul v-show="hasItems">
      <li v-for="(item, $item) in items"
          :key="$item"
          :class="activeClass($item)"
          @mousedown="hit"
          @mousemove="setActive($item)" >
        <span v-text="item.name"></span>
      </li>
    </ul>
  </div>
</template>

<style scoped>
  .Typeahead {
    position: relative;
  }

  .Typeahead__input {
    width: 100%;
    font-size: 13px;
    color: #2c3e50;
    line-height: 1.332857143;
    box-shadow: inset 0 1px 4px rgba(0,0,0,.4);
    -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    font-weight: 300;
    padding: 12px 26px;
    border: none;
    border-radius: 22px;
    letter-spacing: 1px;
    box-sizing: border-box;
  }

  .Typeahead__input:focus {
    border-color: #4fc08d;
    outline: 0;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px #4fc08d;
  }

  .fa-times {
    cursor: pointer;
  }

  i {
    float: right;
    position: relative;
    top: 30px;
    right: 29px;
    opacity: 0.4;
  }

  ul {
    position: absolute;
    padding: 0;
    top: 50%;
    padding: 0;
    margin-top: 28px;
    min-width: 100%;
    background-color: #fff;
    list-style: none;
    border-radius: 4px;
    box-shadow: 0 0 10px rgba(0,0,0, 0.25);
    z-index: 10100;
  }

  li {
    font-size: 13px;
    line-height: 22px;
    padding: 10px 16px;
    border-bottom: 1px solid #ccc;
    cursor: pointer;
    margin: 0;
  }

  li:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  li:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-bottom: 0;
  }

  span {
    display: block;
    color: #2c3e50;
  }

  .active {
    background-color: #EFF2F7;
  }

  .active span {
    color: #2c3e50;
  }

  .name {
    font-weight: 700;
    font-size: 18px;
  }

  .screen-name {
    font-style: italic;
  }
</style>
