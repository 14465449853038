<script>
  export default {
    created () {
      var vm = this;
      var infos = {};
      infos['x'] = {
        label: '不明',
        placeholder: '不明',
        uri: q => `/search/?${q}&mode=0`,
      }; // unknown

      infos[0] = {
        label: 'すべて',
        placeholder: '病気、薬、病院などを検索',
        uri: q => `/search/?${q}&mode=0`,
      };

      infos[10] = {
        label: '病気',
        placeholder: '病名などで病気を検索',
        uri: q => `/diseases/search/?${q}`,
        uri_top: `/diseases/`,
      };

      infos[20] = {
        label: '薬',
        placeholder: '薬名、病名などで薬全般を検索',
        uri: q => `/medicines/search/?${q}&mode=20`,

      };
      infos[21] = {
        label: '処方薬',
        placeholder: '薬名、病名などで処方薬を検索',
        uri: q => `/medicines/search/?${q}&mode=21`,
      };
      infos[22] = {
        label: '市販薬',
        placeholder: '薬名、病名などで市販薬を検索',
        uri: q => `/medicines/search/?${q}&mode=22`,
      };
      infos[23] = {
        label: '薬解説',
        placeholder: '薬名、病名などで薬の解説を検索',
        uri: q => `/medicines/search/?${q}&mode=23`,

      };

      infos[30] = {
        label: '病院',
        placeholder: '病院を検索',
        uri: q=>`/institutions/search/?${q}`,
        uri_top: '/institutions/',
      };

      infos[40] = {
        label: 'ニュース',
        placeholder: 'ニュースを検索',
        uri: q=>`/news/search/?${q}`,
        uri_top: `/news/`,
      };
      vm.mode_infos = infos;

      // TODO: init_info でvuex領域にクライアント側のマスタ構築する。
      vm.condition = {};
      vm.condition[30] = {}; // 30 is institution mode.
      vm.condition[30]['inst_type'] = [
        {label: 'すべて', value: ''},
        {label: '病院', value: 'hospital'},
        {label: 'クリニック', value: 'clinic'},
      ];
      vm.condition[30]['availability'] = [
        {label: '土曜診察', value: 'saturday'},
        {label: '日曜診察', value: 'sunday'},
        {label: '休日診察', value: 'holiday'},
        {label: '深夜診察', value: 'late_evening'},
      ];
      vm.master = {};
      vm.master[30] = {}; // 30 is institution mode.
      Object.keys(vm.condition[30]).forEach(k=>{
        var v = vm.condition[30][k];
        v.forEach(vv=>{
          vm.master[30][k] = vm.master[30][k] || {};
          vm.master[30][k][vv.value] = vv.label;
        });
      });
    },
    methods: {
      valid_emailp (x) {
        return x.match(/^([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})$/i);
      },
      to_base32 (i) {
        return i.toString(32);
      },
      from_base32 (x) {
        return parseInt(x, 32);
      },
      modal (modal_opts) {
        var vm = this;
        vm.$set(vm.$store.state, 'modal_opts', modal_opts);
      },
      tooltip (opts = null) {
        var vm = this;
        // optsは、{content: 'xxx', top: 10, left: 20} と言う形式。
        // nullであればtooltip_viewを非表示にする。
        vm.$set(vm.$store.state, 'tooltip', opts);
      },
      nop () {
        var vm = this;
        vm.logd("nop");
      },
      presentp (x) {
        if (!x) return false;
        if (x.length === 0) return false;
        return true;
      },
      truncate (x, len) {
        var vm = this;
        if (!vm.presentp(x)) {
          return x;
        }
        if (x.length <= len) {
          return x;
        }
        return `${x.slice(0, len)} ...`;
      },
      set_qs_and_go (k, v) {
        var vm = this;
        var qs = location.search;
        var qh = vm.$qs.parse(qs);
        if (vm.presentp(v)) {
          qh[k] = v;
        } else {
          delete qh[k];
        }
        qs = vm.$qs.stringify(qh);
        location.replace('/d/prescription/search/?' + qs);
      },
      toast (message, opts = {duration: 2000}) {
        var vm = this;
        opts['position'] = 'bottom-right';
        if (opts['warnp']) {
          opts['duration'] = null;
          opts['theme'] = 'bubble';
        }

        var toast = vm.$toasted.show(message, opts);
        toast.el.style.cssText += ' border-radius: 5px; padding-right: 8px;';
        var close = document.createElement('button');
        close.className = 'delete xis-large is-ml8 is-pulled-right';
        close.addEventListener('click', ()=>{
          toast.goAway(0);
        });
        toast.el.appendChild(close);
      },
      mhttp (method, uri, opts, hook_then, hook_catch) {
        var vm = this;

        var tag = parseInt(Math.random() * 100);
        var _hook_catch = hook_catch || function (e) {
          var msg = `通信エラー:${e.response.status} method:${method} uri:${uri} `;
          if (vm.presentp(e.response.data && vm.presentp(e.response.data.error))) {
            var estr = e.response.data.error.toString();
            estr = estr.replace(/\n/g, '<br>');
            estr = estr.replace(/ /g, '&nbsp;');
            vm.logd(estr);
            msg += `error:${estr}`;
          }
          vm.toast(msg, {warnp: true});
          vm.logd('connection error:', e);
          window.e = e;
          return true;
        };
        var ctx = vm.$http[method](uri, opts);
        ctx.then(res=>{
          hook_then(res);
        });
        ctx.catch(_hook_catch);
      },
      string_trim (len, x) {
        if (x.length <= len) {
          return x;
        }
        return x.slice(0, len - 3) + '...';
      },

      qgenericp_to_name (x) {
        if (x === 1) {
          return '後発除く';
        }
        if (x === 2) {
          return '後発のみ';
        }
        return '';
      },

      mode_to_info (mode) {
        var vm = this;
        var x = vm.mode_infos[mode] || vm.mode_infos['x'];
        return x;
      },
      mode_to_searchname (mode) {
        var vm = this;
        var info = vm.mode_to_info(mode);
        return info.label;
      },
      mode_from_uri () {
        var vm = this;
        // get mode from uri
        var xuri = window.location.toString();
        var xmode = -1;
        if (null) {

        } else if (xuri.match(new RegExp('/diseases'))) {
          xmode = 10;
        } else if (xuri.match(new RegExp('/medicines/prescription'))) {
          xmode = 21;
        } else if (xuri.match(new RegExp('/medicines/otc'))) {
          xmode = 22;
        } else if (xuri.match(new RegExp('/medicines/article'))) {
          xmode = 23;
        } else if (xuri.match(new RegExp('/medicines/search'))) {
          xmode = 20;
          var xm = xuri.match(new RegExp('mode=([0-9]+)'));
          if (xm) {
            xmode = xm[1];
          }
        } else if (xuri.match(new RegExp('/medicines'))) {
          xmode = 20;
        } else if (xuri.match(new RegExp('/institutions'))) {
          xmode = 30;
        } else if (xuri.match(new RegExp('/news'))) {
          xmode = 40;
        } else if (xuri.match(new RegExp('/symptoms'))) {
          xmode = 10;
        } else if (xuri.match(new RegExp('/search'))) {
          xmode = 0;
        } else {
          xmode = -1;
        }

        return xmode;
      },
      hash_to_paramstr (h) {
        var vm = this;
        var xparam = [];
        var paren_string_uri_encoded = encodeURIComponent('[]');
        Object.keys(h).filter(keyq=>vm.presentp(h[keyq])).forEach(keyq=>{
          var valq = h[keyq];
          if (Array.isArray(valq)) {
            valq.forEach(xvalq=>{
              xvalq = encodeURIComponent(xvalq);
              xparam.push(`${keyq}${paren_string_uri_encoded}=${xvalq}`);
            });
          } else {
            valq = encodeURIComponent(valq);
            xparam.push(`${keyq}=${valq}`);
          }
        });
        var xparamstr = xparam.join('&');
        return xparamstr;
      },
      coerce_array (x) {
        if (!x) {
          return x;
        }
        if (Array.isArray(x)) {
          return x;
        } else {
          return [x];
        }
      },
    },
  };
</script>
