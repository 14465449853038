<script>
  import lib from './lib.vue';
  export default {
    mixins: [lib],
    data () {
      return {
        item: '',
      };
    },
    props: [
      'institution'
    ],
    created () {
      var vm = this;
      vm.item = vm.institution;
    },
  };
</script>

<template>
  <a :href='`/institutions/${item.id}/`' class="link-gray d-b">
    <label class="tag is-light">
      {{ item.inst_type == 'hospital' ? '病院' : 'クリニック' }}
    </label>
    <h1 class="c-h1-card" style='display: inline;'>
      <a :href='`/institutions/${item.id}/`' class="decorative-link">{{ item.name }}</a>
    </h1>
    <p class='mt mb0 is-size-6'
       v-if="item.consultation_hours">
      <span class="fw-b">診療科：</span>
      <span v-for='(k, v) in item.consultation_hours.slice(0, 5)' class="mr5" :key='v'>{{ k.name }}
      </span>
      <span v-if='item.consultation_hours.length > 5'>...</span>
    </p>
    <div class='is-size-6'>
      <div class="mt0 mb5 clearfix">
        <div class="fl-l w20">
          <i class="icon-address fc-bg500 mr5"></i>
        </div>
        <div class="ml20">
          〒{{ item.postal_code }}
          {{ item.address }}
        </div>
      </div>

      <div class="mt0 mb5 clearfix">
        <div class="fl-l w20">
          <i class="icon-tel fc-bg500 mr5"></i>
        </div>
        <div class="ml20">
          {{item.tel_day}}
        </div>
      </div>
      <div class="mt0 mb5 clearfix" v-if='presentp(item.access)'>
        <div class="fl-l w20">
          <i class="icon-access fc-bg500 mr5"></i>
        </div>
        <div class="ml20">
          {{truncate(item.access, 50)}}
        </div>
      </div>
    </div>
  </a>
</template>
