<script>
  import lib from './lib.vue';
  import sinputDiseaseSymptom from './sinput_disease_symptom.vue';
  import sinputInstitutionLocation from './sinput_institution_location.vue';
  import axios from 'axios';
  import mselect from './mselect.vue';

  var tokens = document.getElementsByName('csrf-token');
  if (tokens.length) {
    axios.defaults.headers['X-CSRF-Token'] = tokens.item(0).content;
  }
  export default {
    mixins: [lib],
    components: {
      sinputDiseaseSymptom,
      sinputInstitutionLocation,
      mselect,
    },
    props: [
      'p_selected',
      'p_conditions',
      'baseUrl',
      'p_search',
      'p_hide_toggle_button',

      'disp_mode', // parent_disp_mode. UGLY HACK but work...
      'toggle_disp_mode',
    ],
    data () {
      return {
        location_search_mode: 0,
        // freeWordSearch: false,
        base_url: '/institutions',
        // showArea: true,
        // showStation: false,
        condition_availability: [],
        conditions: {},
        selected_init: {
          _label: {
            line: '',
            station: '',
            field: '',
            disease: '',
            symptom: '',
          },
          availabilities: [],
          size: 20,

          q: '',
          pref: '',
          city: '',
          town: '',
          station_pref: '',
          line: '',
          station: '',
          field: '',
          disease: '',
          symptom: '',

          inst_type: '',

          treatment: '',

          specialist: '',
          specialty_outpatient: '',
          preventive_inoculation: '',
          // ptn: '',
        },
        selected: {},
        selected_inst_type: '',
        disease_symptom_label: '', // 病気・症状検索コンポーネントへデフォルト値を渡すため
        location_label: '', // 地名・駅名検索コーンポーネントへデフォルト値を渡すため

        location_label_disp_only: '', // UGLY hack.

        prefs: [],
      };
    },
    // watch: {
    //   selected: {
    //     handler (_to, _fr) {
    //       var vm = this;
    //       var x = {...vm.selected};
    //       delete x._label;
    //       this.logd("x:", x);
    //       vm.$set(vm.$store.state, 'qs', x);
    //     },
    //     deep: true,
    //   }
    // },
    computed: {
      // selected: {
      //   set (x) {
      //     var vm = this;
      //     this.logd("town:", vm.$store.state.qs.town);
      //     vm.$set(vm.$store.state, 'qs', x);
      //   },
      //   get () {
      //     var vm = this;
      //     return vm.$store.state.qs;
      //   },
      // },
    },
    created () {
      var vm = this;

      vm.condition_inst_type = [
        {label: 'すべて', value: ''},
        {label: '病院', value: 'hospital'},
        {label: 'クリニック', value: 'clinic'},
        // {label: '歯科診療所', value: 'dental'},
      ];
      vm.condition_availability = [
        {label: '土曜診察', value: 'saturday'},
        {label: '日曜診察', value: 'sunday'},
        {label: '休日診察', value: 'holiday'},
        {label: '深夜診察', value: 'late_evening'},
      ];

      this.logd("ps:", vm.p_selected);
      this.logd("pc:", vm.p_conditions);
      if (vm.p_selected) {
        vm.selected = vm.p_selected;
      } else {
        vm.selected = {...vm.selected_init};
      }
      if (vm.p_conditions) {
        vm.conditions = vm.p_conditions;
      } else {
        vm.conditions = {};
        vm.getFormData();
      }

      // to detect the change.
      Object.keys(vm.selected_init).forEach(k=>{
        if (vm.presentp(vm.selected[k])) {
        } else {
          this.logd("SET");
          if (k === '_label') {
            vm.$set(vm.selected, k, {});
          } else if (k === 'availabilities') {
            this.logd("availabilities");
            vm.$set(vm.selected, k, []);
          } else if (k === 'size') {
            vm.$set(vm.selected, k, 20);
          } else {
            vm.$set(vm.selected, k, '');
          }
        }
      });

      if (vm.presentp(vm.selected.disease)) {
        vm.disease_symptom_label = vm.selected._label.disease[vm.selected.disease];
      } else if (vm.presentp(vm.selected.symptom)) {
        vm.disease_symptom_label = vm.selected._label.symptom[vm.selected.symptom];
      }

      if (vm.presentp(vm.selected.station)) {
        vm.mset_location_search_mode(1);
      }

      // this part causes the trouble in windows.
      if (vm.presentp(vm.selected.station)) {
        vm.location_label_disp_only = `${vm.selected._label.station[vm.selected.station]}駅`;
      } else {
        ['town', 'city', 'pref'].every((x, idx)=>{
          if (vm.presentp(vm.selected[x])) {
            vm.location_label_disp_only = vm.selected[x];
            return false;
          }
          return true;
        });
      }

      vm.link_infos = [];
      vm.link_infos.push(['/institutions/map', '地図から探す']);
      vm.link_infos.push(['/institutions/specialist', '専門医から探す']);
      vm.link_infos.push(['/institutions/specialty_outpatient', '専門外来から探す']);
      vm.link_infos.push(['/institutions/preventive_inoculation', '予防接種から探す']);

      if (vm.baseUrl !== '') {
        vm.base_url = vm.baseUrl;
        if (vm.$session.get('users_inst_gmap_size')) {
          vm.selected.size = vm.$session.get('users_inst_gmap_size');
        }
      }

      vm.prefs = [
        "北海道", "青森県", "岩手県", "宮城県", "秋田県", "山形県", "福島県", "東京都", "神奈川県", "埼玉県", "千葉県", "茨城県", "栃木県", "群馬県",
        "新潟県", "富山県", "石川県", "福井県", "山梨県", "長野県", "愛知県", "岐阜県", "静岡県", "三重県", "大阪府", "兵庫県", "京都府", "滋賀県",
        "奈良県", "和歌山県", "鳥取県", "島根県", "岡山県", "広島県", "山口県", "徳島県", "香川県", "愛媛県", "高知県", "福岡県", "佐賀県", "長崎県",
        "熊本県", "大分県", "宮崎県", "鹿児島県", "沖縄県"];
    },
    methods: {
      clear_selected_location () {
        var vm = this;
        vm.selected['pref'] = '';
        vm.selected['city'] = '';
        vm.selected['town'] = '';

        vm.selected['station_pref'] = '';
        vm.selected['station'] = '';
        vm.selected['line'] = '';
      },
      clear_selected () {
        var vm = this;
        vm.selected = {...vm.selected_init};
        this.logd("getFormData clear_selected");
        vm.location_label_disp_only = '';
        vm.getFormData();
      },
      search () {
        var vm = this;
        var x = {...vm.selected};
        delete x._label; // ugly hack.
        delete x.size;   // ugly hack.
        x.q = vm.$store.state.qs.q;
        var xparam = vm.hash_to_paramstr(x);
        this.logd("xparam:", xparam);
        window.location.href = xparam === '' ? '/institutions/' : `/institutions/?${xparam}`;
        // window.location.pathname = '/institutions';
        // window.location.search = xparam;
      },
      getFormData () {
        var vm = this;
        axios.post('/api/u/institution_search/form_data', vm.selected).then(res=>{
          // vm.selected = res.data.selected;
          // this.logd("update selected old:", vm.selected);
          // this.logd("update selected new:", res.data.selected_neat);
          // vm.selected = res.data.selected_neat;
          this.logd("form_data called");

          vm.conditions = res.data.conditions;

          if (vm.presentp(vm.selected.disease)) {
            vm.disease_symptom_label = vm.selected._label.disease[vm.selected.disease];
          } else if (vm.presentp(vm.selected.symptom)) {
            vm.disease_symptom_label = vm.selected._label.symptom[vm.selected.symptom];
          }
        }).catch(res=>{
          this.logd("catch:", res);
        });
      },
      getCities () {
        var vm = this;

        vm.selected.station_pref = '';
        vm.selected.line = '';
        vm.selected.station = '';

        vm.selected.city = '';
        vm.selected.town = '';

        this.logd("getFormData getCities");
        vm.getFormData();
      },
      getTowns () {
        // this.logd("getTowns");
        this.logd("getFormData getTowns");
        this.getFormData();
      },
      getLines () {
        var vm = this;
        vm.selected.pref = '';
        vm.selected.city = '';
        vm.selected.town = '';

        vm.selected.line = '';
        vm.selected.station = '';

        this.logd("getFormData getLines");
        this.getFormData();
      },
      getStations () {
        this.logd("getFormData getStations");
        this.getFormData();
      },

      mset_location_search_mode (x) {
        var vm = this;
        vm.location_search_mode = x;
      },
      set_disease_symptom (item) {
        var vm = this;

        vm.selected[item.type] = '';
        vm.selected[item.type] = item.id;
        vm.selected['_label'][item.type] = item.name;
        vm.disease_symptom_label = item.name;
        // vm.disease_symptom_label = item.name;

        vm.search();
      },
      reset_disease_symptom () {
        var vm = this;
        ['disease', 'symptom'].forEach(type=>{
          vm.selected[type] = '';
          vm.selected[type] = '';
          vm.selected['_label'][type] = '';
          vm.disease_symptom_label = '';
        });
        // vm.disease_symptom_label = item.name;
        // vm.search();
      },
      set_location (item) {
        var vm = this;
        vm.selected['size'] = vm.$session.get('users_inst_gmap_size');
        vm.clear_selected_location();
        if (item.type === 'station') {
          vm.selected['station'] = item.data['code'];
          vm.selected['line'] = item.data['line_code'];
        } else if (item.type === 'prefecture') {
          vm.selected['pref'] = item.data;
        } else if (item.type === 'city') {
          vm.selected['city'] = item.data['city'];
          vm.selected['pref'] = item.data['pref'];
        } else if (item.type === 'town') {
          vm.selected['town'] = item.data['town'];
          vm.selected['city'] = item.data['city'];
          vm.selected['pref'] = item.data['pref'];
        }
        vm.search();
      },
      clearDiseaseSymptom () {
        this.disease_symptom_label = '';
        this.selected['disease'] = '';
        this.selected['symptom'] = '';
        this.selected['specialist'] = '';
        this.selected['treatment'] = '';
      }
    }
  };
</script>

<template>
  <div style='margin: 0px 0 0; padding: 0;'>

    <!-- ------------------------------ -->
    <div class='columns is-mobile is-0'
         style='position: relative;'
    >
      <div class='column'>
        <sinput-institution-location
            v-model="location_label"
            :value_disp_only='location_label_disp_only'
            @minput="set_location"
            p_placeholder='駅・地名'
            p_src='/api/u/institution_search/sstation_location'
            :not_standalone_p='true'
        />
      </div>

      <div class='column is-narrow'>
        <div style='display: flex; justify-content: center; align-items: center; width: 1.4em; height: 100%;'>
          <i class='fa fa-close'></i>
        </div>
      </div>

      <div class='column'>
        <div class='field'>
          <div class='select is-fullwidth'>
            <select name="field" v-model="selected.field" @change='search();'>
              <option value="">診療科</option>
              <option v-for="field in conditions.fields" :key='field.value' :value="field.value">{{ field.label }}</option>
            </select>
          </div>
        </div>
      </div>
    </div>

    <div v-if='(disp_mode == 2 || disp_mode == 3) && !p_hide_toggle_button'
         class='field'
         style='margin: 0.5rem 0 0;'
    >
      <div class='control is-expanded'>
        <a href='#'
           @click='toggle_disp_mode();'
           class='xbutton'
           style="display: block; background-color: #f5f7fa; padding: 4px; margin: 0 -0.5rem; text-align: center;"
           :style='{"border-radius": disp_mode == 2 ? "0px 0px 6px 6px" : "0"}'
        >
          <template v-if='disp_mode == 2'>
            <i class='fa fa-plus' />
            詳細条件
          </template>
          <template v-else>
            <i class='fa fa-minus' />
            詳細条件
          </template>

        </a>
      </div>
    </div>

    <div v-show='disp_mode == 1 || disp_mode == 3'>

      <br />

      <!-- ------------------------------ -->
      <div class='field'>
        <!-- p>地域・駅で絞り込む</p -->
        <div class='tabs xis-toggle is-boxed is-fullwidth' style='margin-bottom: 0;'>
          <ul>
            <li :class='{"is-active": location_search_mode === 0}'>
              <a @click='mset_location_search_mode(0)'>
                地域
              </a>
            </li>
            <li :class='{"is-active": location_search_mode === 1}'>
              <a @click='mset_location_search_mode(1)'>
                駅
              </a>
            </li>
          </ul>
        </div>

        <!-- ---------- -->
        <div v-show='location_search_mode === 0' style='border: 1px solid #e0e6ed; border-top: 0; padding: 10px;'>
          <div v-if='presentp(conditions.prefs)'>
            <div class='field'>
              <div class="select is-fullwidth">
                <select @change="getCities" name="pref" v-model="selected.pref">
                  <option value="">全国</option>
                  <option v-for='xpref in conditions.prefs' :value='xpref.value' :key='xpref.value'>
                    {{xpref.label}} <!--  ({{xpref.count}}) -->
                  </option>
                </select>
              </div>
            </div>
            <div class='field'>
              <div class='select is-fullwidth' v-show='presentp(selected.pref)'>
                <select @change="getTowns" name="city" v-model="selected.city">
                  <option value="">- 市区町村を選択 -</option>
                  <option v-for="city in conditions.cities" :value="city.value" :key='city.value'>
                    {{ city.label }} ({{ city.count }})
                  </option>
                </select>
              </div>
            </div>
            <div class='field'>
              <div class='select is-fullwidth' v-show="presentp(selected.city)">
                <select name="town" v-model="selected.town">
                  <option value="">- 町域を選択 -</option>
                  <option v-for="town in conditions.towns" :value="town.value" :key='town.value'>
                    {{ town.label }} ({{ town.count }})
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div v-else class='is-pink'>
            条件に合う病院が見つかりません。
            地域で絞り込むためには条件を見直す必要があります。
          </div>

        </div>

        <!-- ---------- -->
        <div v-show='location_search_mode === 1' style='border: 1px solid #e0e6ed; border-top: 0; padding: 10px;'>
          <div class='field'>
            <div class="select is-fullwidth">
              <select @change="getLines" name="station_pref" v-model="selected.station_pref">
                <option value="">全国の駅</option>
                <option v-for='xpref in prefs' :value='xpref' v-text='xpref' :key='xpref' />
              </select>
            </div>
          </div>
          <div class='field'>

            <div class='select is-fullwidth' v-show='presentp(selected.station_pref)'>
              <select @change="getStations" name="line" v-model="selected.line">
                <option value="">- 路線を選択 -</option>
                <option v-for="line in conditions.lines" :value="line.value" :key='line.value'>{{ line.label }}</option>
              </select>
            </div>
          </div>
          <div class='field'>

            <div class='select is-fullwidth' v-show='presentp(selected.line)'>
              <select name="station" v-model="selected.station">
                <option value="">- 駅を選択 -</option>
                <option v-for="station in conditions.stations" :value="station.value" :key='station.value'>{{ station.label }}駅</option>
              </select>
            </div>
          </div>
          <div v-if='presentp(selected.station_pref) && !presentp(selected.line)' class='is-pink has-text-weight-bold'>
            路線を選択してください
          </div>
          <div v-else-if='presentp(selected.station_pref) && !presentp(selected.station)' class='is-pink has-text-weight-bold'>
            駅を選択してください
          </div>
        </div>
      </div>

      <!-- ------------------------------ -->
      <div class='field'>
        <p>症状・病名</p>
        <sinput-disease-symptom
            v-model="disease_symptom_label"
            @minput="set_disease_symptom"
            @mreset='reset_disease_symptom'
        />
      </div>


      <div class='field'>
        <p>その他の条件</p>
        <mselect :p_condition='condition_availability' v-model='selected.availabilities' :child_class='{"is-6": true}' />
        <br />
        <mselect :radiop='true' :p_condition='conditions.inst_types' v-model='selected.inst_type' :child_class='{"is-6": true}' />
        <div class='is-size-6 is-gray' style='margin-top: 8px;'>
          ※ クリニックは病床数20未満の施設
        </div>
        <br />
      </div>

      <div class='field'>
        <div class="control has-text-centered">
          <button class='button is-expanded xis-large' @click='clear_selected'>
            全てクリア
          </button>
          &nbsp;&nbsp;
          <button
              class='button is-expanded xis-large is-primary xicon-search'
              @click='p_search()'>
            <i class='fa fa-search'></i>
            &nbsp;
            検索
          </button>
        </div>
      </div>

      <div style='height: 8px;' />
    </div>

  </div>
</template>

<style scoped>
  p {
    font-weight: bold;
    margin: 0;
  }
  .box > a.button {
    margin-bottom: 4px;
  }
</style>
