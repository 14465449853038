<script>
  import mselect from './mselect.vue';
  export default {
    components: {
      mselect,
    },
    props: {
      p_label: { default: '' },
      value: { default: false },
    },
    created () {
    },
    methods: {
      input (x) {
        var vm = this;
        if (x.length > 0 && x[0] === 'true') {
          vm.$emit('input', 'true');
        } else {
          vm.$emit('input', '');
        }
      },
    },
  };
</script>

<template>
  <mselect :p_condition='[{label: p_label, value: "true"}]' :value='[value]' @input='input' />
</template>
