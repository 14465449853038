<script>
  import lib from './lib.vue';
  export default {
    mixins: [lib],
    props: {
      radiop: {default: false},
      p_condition: {default: ()=>{return [];}},
      value: null,
      child_class: {},
    },
    data () {
      return {
        ivalue: {},
      };
    },
    created () {
      var vm = this;
      // vm.$emit('input', vm.value);
      // if (vm.radiop) {
      //   vm.ivalue[vm.value] = true;
      // } else {
      //   vm.value.forEach(k=>{
      //     vm.ivalue[k] = true;
      //   });
      // }
      vm.update();
    },
    watch: {
      value (_to, _fr) {
        var vm = this;
        vm.update();
      },
    },
    computed: {
    },
    methods: {
      update () {
        var vm = this;
        if (vm.radiop) {
          vm.p_condition.forEach(c=>{
            vm.ivalue[c.value] = c.value === vm.value;
          });
        } else {
          vm.p_condition.forEach(c=>{
            vm.ivalue[c.value] = (vm.value.indexOf(c.value) !== -1);
          });
        }
      },
      poke (x) {
        var vm = this;
        if (vm.radiop) {
          vm.$emit('input', x);
        } else {
          var ks = [];
          vm.$set(vm.ivalue, x, !vm.ivalue[x]);
          Object.keys(vm.ivalue).forEach(k=>{
            if (vm.ivalue[k]) {
              ks.push(k);
            }
          });
          this.logd("ks:", ks);
          vm.$emit('input', ks);
        }
      },
    },
  };
</script>

<template>
  <div class='columns is-0 is-multiline is-mobile'>
    <template v-for='(c, index) in p_condition' :index='index'>
      <div class='column' @click='poke(c.value)' :class='child_class' :key='index'>
        <div class='columns is-0 is-mobile' v-if="!c.hidden">
          <div class='column is-narrow' style='margin: auto;'>
            <div class='mbox' :class='{activep: ivalue[c.value], radiop: radiop}'>
              <template v-if='ivalue[c.value]'>
                <div v-if='radiop' class='fa fa-circle' />
                <div v-else class='fa fa-check' />
              </template>
            </div>
          </div>
          &nbsp;
          <div class='column is-size-6' style='margin: auto;'>
            {{c.label}}
          </div>
        </div>
      </div>
    </template>


    <div class='box' style='display: none;'>
      {{value}}
    </div>
  </div>
</template>

<style scoped>
  .mbox {
    width: 20px;
    height: 20px;
    border: 2px solid #e0e6ed;

    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
  }
  .mbox > div {
    color: #fa6470;
    font-size: 15px;
  }
  .mbox.radiop {
    border-radius: 10px;
  }
</style>

