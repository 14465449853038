require('es6-promise').polyfill();

import vue from 'vue';
import axios from 'axios';

import buefy from 'buefy';
vue.use(buefy, {defaultIconPack: 'fas'});

import VueSession from 'vue-session';
import Toasted from 'vue-toasted';
import qs from 'query-string';
import Vuex from 'vuex';
import RegexTrie from 'regex-trie';
import vueCheckboxRadio from 'vue-checkbox-radio';

import initInfo from './users/components/init_info.vue';
import modal from './users/components/modal.vue';
import naviBox from './users/components/navi_box.vue';
import naviBoxAside from './users/components/navi_box_aside.vue';
import modalReactionTrigger from './users/components/modal_reaction_trigger.vue';
import searchBox from './users/components/search_box.vue';
import searchCond from './users/components/search_cond.vue';
import accordion from './users/components/accordion.vue';
import accordionAtCard from './users/components/accordion_at_card.vue';
import mfooter from './users/components/mfooter.vue';
import institutionSearchMap from './users/components/institution_search_map.vue';
// import lightbox from './users/components/lightbox.vue';
import otcImages from './users/components/otc_images.vue';
import tooltip from './users/components/tooltip.vue';
import tooltipView from './users/components/tooltip_view.vue';
import spIconMenu from './users/components/sp_icon_menu.vue';
import dropdown from './users/components/dropdown.vue';

import scInit from './users/components/sc_init.vue';
import scSinput from './users/components/sc_sinput.vue';
import scAskDetail from './users/components/sc_ask_detail.vue';
import scCond from './users/components/sc_cond.vue';
import scImportant from './users/components/sc_important.vue';

import contact from './users/components/contact.vue';

import prefLink from './users/components/pref_link.vue';

import mstick from './users/components/mstick.vue';

import meRegister from './users/components/me_register.vue';
import meLogin from './users/components/me_login.vue';
import meMenu from './users/components/me_menu.vue';

window.addEventListener('load', () => {
  vue.use(Toasted);
});
vue.use(VueSession);
vue.use(Vuex);
vue.use(vueCheckboxRadio);

var csrf_token = document.getElementsByName('csrf-token');
if (csrf_token.length) {
  axios.defaults.headers['X-CSRF-Token'] = csrf_token.item(0).content;
}

document.addEventListener('DOMContentLoaded', () => {
  vue.prototype.mset_location = (uri) => {
    window.location = uri;
  };
  vue.prototype.csrf_token = ()=>{
    var csrf_token = document.getElementsByName('csrf-token');
    if (csrf_token.length) {
      return axios.defaults.headers['X-CSRF-Token'] = csrf_token.item(0).content;
    }
    return;
  };

  vue.prototype.totop = () => window.scrollTo(0, 0);
  vue.prototype.$http = axios;
  vue.prototype.$qs = qs;
  // ref: http://blog.szmake.net/archives/958
  vue.prototype.ie11hell_p = !!window.MSInputMethodContext && !!document.documentMode;
  vue.prototype.logd = function () {}; // logd_enable されていないときに呼び出された場合はなにもしない
  vue.prototype.logd_enable = () => {
    // arguments の this の束縛をする必用があるため、ここは function を使う
    vue.prototype.logd = function () {
      console.log.apply(vue.prototype, arguments);
    };
  };
  vue.prototype.regex_trie = () => new RegexTrie();
  // vue.prototype.set_content_visible = (visiblep) => {
  //   const styleDisplay = visiblep ? 'block' : 'none';
  //   document.getElementById('ghost-app-main').style.display = styleDisplay;
  //   document.getElementById('ghost-app-footer').style.display = styleDisplay;
  // };
  const store = new Vuex.Store();
  window.dstore = store; // for check.
  vue.prototype.scroll_yvec = () => store.state.page_y_offset - store.state.page_y_offset_prev; // y軸方向のスクロール量

  // vue.prototype.mset_location_of_search = ()=>{
  //   var sm = (store.state && store.state.search_mode) || 0;
  //   if (null) {
  //   } else if (0 === sm) {
  //     window.location = '/search/';
  //   } else if (10 === sm) {
  //     window.location = '/diseases/?schp=1';
  //   } else if (20 === sm) {
  //     window.location = '/medicines/';
  //   } else if (21 === sm) {
  //     window.location = '/medicines/prescription/?schp=1';
  //   } else if (22 === sm) {
  //     window.location = '/medicines/otc/?schp=1';
  //   } else if (23 === sm) {
  //     window.location = '/medicines/article/?schp=1';
  //   } else if (30 === sm) {
  //     window.location = '/institutions/';
  // 
  //     // } else if (40 === sm) {
  //     //   window.location = '/news/search/';
  //   } else {
  //     window.location = '/search/';
  //   }
  // };

  // vue.prototype.poke_searchingp = ()=>{
  //   if (window.pageYOffset > 0 && store.state.searchingp == 1) {
  //     window.scrollTo(0, 0);
  //   } else if (store.state.searchingp == 1) {
  //     vue.set(store.state, 'searchingp', null);
  //   } else {
  //     window.scrollTo(0, 0);
  //     vue.set(store.state, 'searchingp', 1);
  //   }
  // },

  vue.prototype.poke_searchingp = ()=>{
    if (store.state.searchingp == 1) {
      vue.set(store.state, 'searchingp', null);
    } else {
      window.scrollTo(0, 0);
      vue.set(store.state, 'searchingp', 1);
    }
  },

  new vue({
    el: '#ghost-app',
    store,
    created() {
      // for debug.
      // window.dapp = this;
    },
    components: {
      // http://yanamura.hatenablog.com/entry/2017/09/17/120421
      // root-vue-component(app) can't recieve props...
      initInfo,
      modal,
      naviBox,
      naviBoxAside,
      modalReactionTrigger,
      searchBox,
      searchCond,

      accordion,
      accordionAtCard,
      mfooter,
      institutionSearchMap,
      // lightbox,
      otcImages,
      tooltip,
      tooltipView,
      spIconMenu,
      dropdown,

      scInit,
      scSinput,
      scAskDetail,
      scCond,
      scImportant,

      contact,

      prefLink,

      mstick,

      meRegister,
      meLogin,
      meMenu,

      ///// TODO: trash them.
      //tabs,
      //tab,
      //expand,
      //prefsSearchLinksTransition,
      //linesSearchLinks,
      //areaLinesContent,
    },
  });
});
